"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const style_1 = require("./style");
const style_2 = require("../../ui/Box/style");
const D = __importStar(require("../../ui/Display"));
const S = __importStar(require("./style"));
function Accent({ name = '', label = '', inform = '', children = (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}) }) {
    return ((0, jsx_runtime_1.jsxs)(D.FlexCols, { _gap: 16, _width: "100%", children: [(0, jsx_runtime_1.jsx)(style_1.AccentLabel, { as: name.length > 0 ? 'label' : 'div', ...(name.length > 0 ? { htmlFor: name } : {}), children: (0, jsx_runtime_1.jsx)("span", { children: label }) }), inform.length > 0 && (0, jsx_runtime_1.jsx)(style_1.Inform, { children: inform }), (0, jsx_runtime_1.jsx)(style_2.Box, { _width: "100%", _padding: "0", _margin: "0", children: children })] }));
}
function Required({ name = '', label = '', inform = '', children = (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}) }) {
    return ((0, jsx_runtime_1.jsxs)(D.FlexCols, { _gap: 6, _width: "100%", children: [(0, jsx_runtime_1.jsx)(style_1.RequiredLabel, { as: name.length > 0 ? 'label' : 'div', ...(name.length > 0 ? { htmlFor: name } : {}), children: (0, jsx_runtime_1.jsx)("span", { children: label }) }), inform.length > 0 && (0, jsx_runtime_1.jsx)(style_1.Inform, { children: inform }), (0, jsx_runtime_1.jsx)(style_2.Box, { _width: "100%", _padding: "0", _margin: "0", children: children })] }));
}
function Default({ name = '', label = '', inform, children, customStyle = {}, _required, _gap = 20, _m_gap = 12, _size, _m_size, _weight, }) {
    return ((0, jsx_runtime_1.jsxs)(D.FlexCols, { _gap: _gap, _m_gap: _m_gap, _width: "100%", _style: customStyle, children: [(0, jsx_runtime_1.jsxs)(S.LabelWrap, { children: [(0, jsx_runtime_1.jsx)(S.Label, { as: name.length > 0 ? 'label' : 'div', ...(name.length > 0 ? { htmlFor: name } : {}), _size: _size, _m_size: _m_size, _weight: _weight, _required: _required, children: label }), inform && inform.length > 0 && (0, jsx_runtime_1.jsx)(S.Inform, { children: inform })] }), (0, jsx_runtime_1.jsx)(S.Contents, { children: children })] }));
}
const Fieldset = {
    Default,
    Accent,
    Required,
};
exports.default = Fieldset;
