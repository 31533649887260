"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Circle = exports.Icon = exports.Round = exports.Square = exports.Block = exports.Complex = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const utils_1 = require("../../../themes/utils");
const Wrapper = styled_components_1.default.div `
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
const CheckBox = styled_components_1.default.div `
  z-index: 21;
  position: relative;
  overflow: hidden;

  input {
    z-index: 22;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }

  label {
    z-index: 23;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 1.5;
    cursor: pointer;
    transition: 0.08s ease-in;
  }

  input:disabled + label {
    opacity: 0.5;
  }
`;
exports.Complex = {
    Wrapper: (0, styled_components_1.default)(Wrapper) `
    gap: 24px;

    @media (${(props) => props.theme.media.tablet}) {
      flex-direction: column;
      gap: 16px;
    }
  `,
    CheckBox: (0, styled_components_1.default)(CheckBox) `
    flex: 1;

    label {
      padding: 16px 0;
      justify-content: center;
      border-radius: 10px;
      border: 1px solid ${(props) => props.theme.colors.gray2};
      color: ${(props) => props.theme.colors.gray8};
      font-size: ${(props) => props.theme.fontSizes.s};
      text-align: center;
    }

    input:checked + label {
      border-color: ${(props) => props.theme.colors.primaryMain};
      color: ${(props) => props.theme.colors.primaryMain};
    }

    label:hover {
      border-color: ${(props) => props.theme.colors.primary0};
      color: ${(props) => props.theme.colors.primary5};
    }

    @media (${(props) => props.theme.media.mobile}) {
      min-height: 48px;
      border-radius: 4px;

      label {
        border-radius: 4px;
      }
    }
  `,
    Text: styled_components_1.default.div `
    display: flex;
    flex-direction: column;
    gap: 6px;

    > * {
      &:first-child {
        font-size: ${(props) => props.theme.fontSizes.base};
        font-weight: ${(props) => props.theme.fontWeights.bold};

        @media (${(props) => props.theme.media.tablet}) {
          font-size: ${(props) => props.theme.fontSizes.s};
        }
      }

      &:not(:first-child) {
        font-size: ${(props) => props.theme.fontSizes.s};
        @media (${(props) => props.theme.media.tablet}) {
          font-size: ${(props) => props.theme.fontSizes.xs};
        }
      }
    }
  `,
};
exports.Block = {
    Wrapper: (0, styled_components_1.default)(Wrapper) `
    flex-direction: row;
    gap: 12px 16px;
  `,
    FullWrapper: (0, styled_components_1.default)(Wrapper) `
    flex-direction: column;
    gap: 16px;

    @media (${(props) => props.theme.media.mobile}) {
      gap: 12px;
    }
  `,
    CheckBox: (0, styled_components_1.default)(CheckBox) `
    width: ${(props) => props._rows && props._rows > 1
        ? `calc(${Math.round(100 / props._rows)}% - ${Math.round((16 * (props._rows - 1)) / props._rows)}px)`
        : '100%'};

    label {
      height: ${(props) => props._height || 48}px;
      justify-content: center;
      border-radius: 2px;
      border: 1px solid ${(props) => props.theme.colors.gray2};
      color: ${(props) => props.theme.colors.gray7};
      font-size: ${(props) => props.theme.fontSizes.xs};
    }

    input:checked + label {
      border-color: ${(props) => props.theme.colors.primaryMain};
      color: ${(props) => props.theme.colors.primaryMain};
    }

    label:hover {
      border-color: ${(props) => props.theme.colors.primary0};
      color: ${(props) => props.theme.colors.primary5};
    }

    @media (${(props) => props.theme.media.mobile}) {
      width: ${(props) => props._m_rows && props._m_rows > 1 ? `calc(${Math.round(100 / props._m_rows)}% - 8px)` : '100%'};

      label {
        height: ${(props) => props._m_height || 44}px;
        border-radius: 4px;
      }
    }
  `,
    FullCheckBox: (0, styled_components_1.default)(CheckBox) `
    width: 100%;

    label {
      height: 52px;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid ${(props) => props.theme.colors.gray2};
      color: ${(props) => props.theme.colors.gray7};
      font-size: ${(props) => props.theme.fontSizes.xs};
    }

    input:checked + label {
      border-color: ${(props) => props.theme.colors.primaryMain};
      color: ${(props) => props.theme.colors.primaryMain};
    }

    label:hover {
      border-color: ${(props) => props.theme.colors.primary0};
      color: ${(props) => props.theme.colors.primary5};
    }

    @media (${(props) => props.theme.media.tablet}) {
      label {
        height: 44px;
        border-radius: 4px;
      }
    }
  `,
};
exports.Square = {
    Wrapper: (0, styled_components_1.default)(Wrapper) `
    gap: 16px;

    @media (${(props) => props.theme.media.mobile}) {
      gap: 12px;
    }
  `,
    CheckBox: (0, styled_components_1.default)(CheckBox) `
    width: calc(25% - 12px);
    height: 100px;

    label {
      justify-content: center;
      border-radius: 4px;
      border: 1px solid ${(props) => props.theme.colors.gray2};
      color: ${(props) => props.theme.colors.gray8};
      font-size: ${(props) => props.theme.fontSizes.s};
      font-weight: ${(props) => props.theme.fontWeights.medium};
    }

    input:checked + label {
      border-color: ${(props) => props.theme.colors.primaryMain};
      color: ${(props) => props.theme.colors.primaryMain};
    }

    label:hover {
      border-color: ${(props) => props.theme.colors.primary0};
      color: ${(props) => props.theme.colors.primary5};
    }

    @media (${(props) => props.theme.media.mobile}) {
      width: calc(50% - 6px);
      height: 52px;
    }
  `,
    MediumWrapper: (0, styled_components_1.default)(Wrapper) `
    gap: 24px;
    @media (${(props) => props.theme.media.mobile}) {
      gap: 12px;
    }
  `,
};
exports.Round = {
    Wrapper: (0, styled_components_1.default)(Wrapper) `
    gap: 8px 8px;
  `,
    CheckBox: (0, styled_components_1.default)(CheckBox) `
    border-radius: 20px;
    overflow: hidden;
    label {
      padding: 8px 16px;
      border-radius: 20px;
      border: 1px solid ${(props) => props.theme.colors.gray1};
      background-color: ${(props) => props.theme.colors.gray1};
      color: ${(props) => props.theme.colors.gray8};
      font-size: ${(props) => props.theme.fontSizes.xs};
    }

    input:checked + label {
      border-color: ${(props) => props.theme.colors.primaryMain};
      background-color: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.primaryMain};
    }

    label:hover {
      border-color: ${(props) => props.theme.colors.primary0};
      color: ${(props) => props.theme.colors.primary5};
    }

    @media (${(props) => props.theme.media.tablet}) {
      label {
        font-size: ${(props) => props.theme.fontSizes.xxs};
      }
    }

    ${(props) => props._type === 'border' &&
        (0, styled_components_1.css) `
        label {
          border: 1px solid ${props.theme.colors.gray2};
          background-color: ${props.theme.colors.white};
        }

        input:disabled + label {
          opacity: 1;
          background-color: ${props.theme.colors.gray2};
          color: ${props.theme.colors.gray4};
        }

        input:disabled + label:hover {
          border-color: ${props.theme.colors.gray2};
        }
      `}
  `,
};
exports.Icon = {
    Wrapper: (0, styled_components_1.default)(Wrapper) `
    display: flex;
    flex-direction: ${(props) => props._direction ?? 'row'};
    gap: ${(props) => (props._gap ? (0, utils_1.cssValue)(props._gap) : '0')};
  `,
    CheckBox: (0, styled_components_1.default)(CheckBox) `
    label {
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 8px 16px;
      border: 1px solid ${(props) => props.theme.colors.gray2};
      border-radius: 2px;
      font-size: ${(props) => props.theme.fontSizes.xs};
      color: ${(props) => props.theme.colors.gray8};
    }

    input:checked + label {
      color: ${(props) => props.theme.colors.primaryMain};
      border-color: ${(props) => props.theme.colors.primary0};
    }

    label:hover {
      border-color: ${(props) => props.theme.colors.primary0};
    }
  `,
    RoundCheckBox: (0, styled_components_1.default)(CheckBox) `
    flex: 1;
    label {
      display: flex;
      gap: 10px;
      align-items: center;
      height: 44px;
      padding: 11px;
      border: 1px solid ${(props) => props.theme.colors.gray2};
      border-radius: 2px;
      font-size: ${(props) => props.theme.fontSizes.s};
      font-weight: ${(props) => props.theme.fontWeights.medium};
      color: ${(props) => props.theme.colors.gray8};
    }

    input:checked + label {
      color: ${(props) => props.theme.colors.primaryMain};
      border-color: ${(props) => props.theme.colors.primaryMain};
    }

    label:hover {
      border-color: ${(props) => props.theme.colors.primaryMain};
    }

    input:disabled + label:hover,
    input:disabled + label {
      color: ${(props) => props.theme.colors.gray5};
    }

    ${(props) => props._size === 'l' &&
        (0, styled_components_1.css) `
        width: 100%;

        label {
          height: 80px;
          padding: 14px;

          @media (${(props) => props.theme.media.tabletSmall}) {
            height: 48px;
          }
        }
      `}
  `,
};
exports.Circle = {
    Wrapper: (0, styled_components_1.default)(Wrapper) `
    flex-directin: column;
    gap: 16px;
  `,
    CheckBox: (0, styled_components_1.default)(CheckBox) `

        label {
            display: gap: 
            gap: 8px;
            align-items: start;

            span {
                flex: 1;
                line-height: 1.5;
                font-size: ${(props) => props.theme.fontSizes.s};
                color: ${(props) => props.theme.colors.gray8};
            }
        }

        ${(props) => props._size === 'l' &&
        (0, styled_components_1.css) `
            label {
              span {
                font-size: ${(props) => props.theme.fontSizes.base};
                font-weight: ${(props) => props.theme.fontWeights.bold};
              }
            }
          `}

    `,
};
