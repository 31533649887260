"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardList = void 0;
const styled_components_1 = __importStar(require("styled-components"));
/* ===== 일반 프로젝트 목록 ===== */
const projectList = (0, styled_components_1.css) `
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media (${(props) => props.theme.media.tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;

    > *:not(.loadingBox) {
      width: calc(50% - 8px);
    }
  }

  @media (${(props) => props.theme.media.tabletSmall}) {
    flex-direction: column;

    > *:not(.loadingBox) {
      width: 100%;
    }
  }
`;
/* ===== 일반 포트폴리오 목록 ===== */
const portfolioList = (0, styled_components_1.css) `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px 30px;
  width: 100%;

  > *:not(.loadingBox) {
    width: calc(33.3333% - 20px) !important;
  }

  @media (${(props) => props.theme.media.tablet}) {
    gap: 32px 20px;

    > *:not(.loadingBox) {
      width: calc(50% - 10px) !important;
    }
  }

  @media (${(props) => props.theme.media.mobile}) {
    flex-direction: column;
    gap: 32px;
    > *:not(.loadingBox) {
      width: 100% !important;
    }
  }
`;
/* ===== 프로젝트 현황 목록 ===== */
const projectStatesList = (0, styled_components_1.css) `
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
/* ===== 내 프로젝트 목록 ===== */
const projectSessionList = (0, styled_components_1.css) `
  ${projectList}

  @media(${(props) => props.theme.media.tablet}) {
    gap: 0;
    padding: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray2};

    > * {
      width: 100% !important;
    }
  }
`;
/* ===== 파트너별 포트폴리오 목록 ===== */
const parterPortfolioList = (0, styled_components_1.css) `
  @media (${(props) => props.theme.media.laptop}) {
    > *:not(.loadingBox) {
      width: calc(50% - 10px) !important;
    }
  }
  ${portfolioList}
`;
/* ===== 추천 파트너 목록 ===== */
const recommendPartnerList = (0, styled_components_1.css) `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;

  > * {
    width: calc(25% - 18px);
  }

  @media (${(props) => props.theme.media.tablet}) {
    > * {
      width: calc(33.3333% - 16px);
    }
  }

  @media (${(props) => props.theme.media.tabletSmall}) {
    > * {
      width: calc(50% - 12px);
    }
  }

  @media (${(props) => props.theme.media.mobile}) {
    display: none;
  }
`;
/* ===== 알림 목록 ===== */
const alarmList = (0, styled_components_1.css) `
    display: flex;
    flex-direction: column;

    > *:not(:first-child) {
        margin-top: 8px;
        border 
    }
`;
exports.CardList = styled_components_1.default.div `
  ${(props) => props.type === 'project' && projectList}
  ${(props) => props.type === 'projectSession' && projectSessionList}
    ${(props) => props.type === 'projectStates' && projectStatesList}
    ${(props) => props.type === 'portfolio' && portfolioList}
    ${(props) => props.type === 'parterPortfolio' && parterPortfolioList}
    ${(props) => props.type === 'recommendPartner' && recommendPartnerList}
    ${(props) => props.type === 'alarm' && alarmList}
`;
