"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRcmdParnterData = void 0;
const text_1 = require("../utils/text");
function getRcmdParnterData(data) {
    const { partnerNo, partnerProfile, partnerId, supportRequestNum, contractNum, completeNum, authCheck } = data;
    return {
        authCheck: authCheck === 3 || authCheck === 5,
        partnerNo: partnerNo || -1,
        partnerProfile: partnerProfile ? partnerProfile.s3Link + partnerProfile.s3FileName : '',
        partnerId: (0, text_1.starString)(partnerId, 3),
        supportRequestNum: `${supportRequestNum || 0} 건`,
        contractNum: `${contractNum || 0} 건`,
        completeNum: `${completeNum || 0} 건`,
    };
}
exports.getRcmdParnterData = getRcmdParnterData;
