"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPartnerPortfolios = exports.delContract = exports.registerContract = exports.requestContract = exports.confirmContract = exports.delQuotation = exports.registerQuotation = exports.requestQuotation = exports.offerReject = exports.offerAccept = exports.getPartnerProjectDetail = exports.getClientProjectDetail = exports.getPartnerAllProjects = exports.getClientAllProjects = exports.confirmMeeting = exports.getMeetingProjects = exports.requestMeeting = exports.checkMeetingRequest = exports.getApplyDetail = exports.getApplyList = exports.requestPartnerCertification = exports.getPartnerCertificationInfo = exports.requestClientCertification = exports.getClientCertificationInfo = exports.modUserInfo = exports.checkModUser = exports.getPartnerScrap = exports.getClientScrap = exports.getPartnerRecentProjects = exports.getClientRecentProjects = exports.getParnterStatus = exports.getClientStatus = exports.modPartnerProfile = exports.modClientProfile = exports.getPartnerInfo = exports.getClientInfo = void 0;
const _1 = require(".");
const urls = {
    userAccount: {
        check: '/api/mypageClient/mypageUserPwdCheck',
        modify: '/api/mypageClient/userInfoModify',
    },
    userInfo: {
        client: '/api/mypageClient/clientInfo',
        partner: '/api/mypagePartner/partnerInfo',
    },
    userProfile: {
        client: '/api/mypageClient/registerProfileClient',
        partner: '/api/mypagePartner/registerProfilePartner',
    },
    certification: {
        details: {
            client: '/api/mypageClient/accountSettingsInfo',
            partner: '/api/mypagePartner/accountSettingsInfo',
        },
        request: {
            client: '/api/mypageClient/accountSettings',
            partner: '/api/mypagePartner/accountSettings',
        },
    },
    apply: {
        list: '/api/mypageClient/supportCheck',
        details: '/api/mypageClient/supportDetail',
    },
    status: {
        client: '/api/mypageClient/progress',
        partner: '/api/mypagePartner/progress',
    },
    allScrapList: {
        client: '/api/mypageClient/interestPortfolio',
        partner: '/api/mypagePartner/interestProject',
    },
    allProjects: {
        client: '/api/mypageClient/allProjects',
        partner: '/api/mypagePartner/allProjects',
    },
    projectDetail: {
        client: '/api/mypageClient/progressDetail',
        partner: '/api/mypagePartner/progressDetail',
    },
    replyOffer: '/api/mypagePartner/acceptOffer',
    quotation: {
        request: '/api/mypageClient/requestQuote',
        register: '/api/mypagePartner/registerQuotes',
        del: '/api/mypagePartner/deleteQuotes',
    },
    contract: {
        request: '/api/mypageClient/reqeustContract',
        register: '/api/mypagePartner/registerContract',
        del: '/api/mypagePartner/deleteContract',
    },
    recentProjects: {
        client: '/api/mypageClient/recentProject',
        partner: '/api/mypagePartner/recentProjectPartner',
    },
    confirmContract: {
        client: '/api/mypageClient/contractConf',
    },
    portfolioList: '/api/mypagePartner/mypagePortfolioList',
    meeting: {
        projects: '/api/mypageClient/projects',
        check: '/api/mypageClient/meetingRequestCheck',
        request: '/api/mypageClient/meetingRequest',
        confirm: '/api/mypagePartner/acceptOffer',
    },
};
/* ==============================
    마이페이지 공통 유저 정보
============================== */
function getClientInfo(userNo) {
    return _1.client.get(urls.userInfo.client, { params: { clientNo: userNo } });
}
exports.getClientInfo = getClientInfo;
function getPartnerInfo(userNo) {
    return _1.client.get(urls.userInfo.partner, { params: { partnerNo: userNo } });
}
exports.getPartnerInfo = getPartnerInfo;
/* ==============================
    마이페이지 프로필 등록
============================== */
function modClientProfile(params) {
    return _1.client.post(urls.userProfile.client, params);
}
exports.modClientProfile = modClientProfile;
function modPartnerProfile(params) {
    return _1.client.post(urls.userProfile.partner, params);
}
exports.modPartnerProfile = modPartnerProfile;
/* ==============================
    마이페이지 홈 프로젝트 현황
============================== */
function getClientStatus(userNo) {
    return _1.client.post(urls.status.client, { userNo: userNo });
}
exports.getClientStatus = getClientStatus;
function getParnterStatus(userNo) {
    return _1.client.post(urls.status.partner, { partnerNo: userNo });
}
exports.getParnterStatus = getParnterStatus;
/* ==============================
    마이페이지 홈 최근 프로젝트
============================== */
function getClientRecentProjects(userNo) {
    return _1.client.get(urls.recentProjects.client, { params: { clientNo: userNo } });
}
exports.getClientRecentProjects = getClientRecentProjects;
function getPartnerRecentProjects(userNo) {
    return _1.client.get(urls.recentProjects.partner, { params: { partnerNo: userNo } });
}
exports.getPartnerRecentProjects = getPartnerRecentProjects;
/* ==============================
    마이페이지 스크랩 목록
============================== */
function getClientScrap(userNo, page) {
    return _1.client.get(urls.allScrapList.client, {
        params: {
            userNo: userNo,
            page: page,
        },
    });
}
exports.getClientScrap = getClientScrap;
function getPartnerScrap(userNo, page) {
    return _1.client.get(urls.allScrapList.partner, {
        params: {
            partnerNo: userNo,
            page: page,
        },
    });
}
exports.getPartnerScrap = getPartnerScrap;
/* ==============================
    계정 회원정보 확인
============================== */
function checkModUser(id, type, pwd) {
    const params = {
        userId: id,
        userType: type === 'client' ? '1' : '2',
        userPwd: pwd,
    };
    return _1.client.post(urls.userAccount.check, params);
}
exports.checkModUser = checkModUser;
function modUserInfo(params) {
    return _1.client.post(urls.userAccount.modify, { ...params, type: 2 });
}
exports.modUserInfo = modUserInfo;
/* ==============================
    계정 - 인증
============================== */
function getClientCertificationInfo(userNo) {
    return _1.client.post(urls.certification.details.client, { userNo: userNo });
}
exports.getClientCertificationInfo = getClientCertificationInfo;
function requestClientCertification(params) {
    return _1.client.post(urls.certification.request.client, params);
}
exports.requestClientCertification = requestClientCertification;
function getPartnerCertificationInfo(userNo) {
    return _1.client.post(urls.certification.details.partner, { userNo: userNo });
}
exports.getPartnerCertificationInfo = getPartnerCertificationInfo;
function requestPartnerCertification(params) {
    return _1.client.post(urls.certification.request.partner, params);
}
exports.requestPartnerCertification = requestPartnerCertification;
/* ==============================
    지원서 확인
============================== */
function getApplyList(projectNo) {
    return _1.client.get(urls.apply.list, {
        params: {
            projectNo: projectNo,
        },
    });
}
exports.getApplyList = getApplyList;
function getApplyDetail(partnerNo, projectNo) {
    return _1.client.get(urls.apply.details, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
        },
    });
}
exports.getApplyDetail = getApplyDetail;
function checkMeetingRequest(params) {
    return _1.client.post(urls.meeting.check, params);
}
exports.checkMeetingRequest = checkMeetingRequest;
function requestMeeting(params) {
    return _1.client.post(urls.meeting.request, {
        ...params,
        type: 2,
    });
}
exports.requestMeeting = requestMeeting;
// 미팅 신청 할 프로젝트 목록 가져오기
function getMeetingProjects(clientNo) {
    return _1.client.get(urls.meeting.projects, {
        params: {
            clientNo: clientNo,
        },
    });
}
exports.getMeetingProjects = getMeetingProjects;
function confirmMeeting(params) {
    return _1.client.get(urls.meeting.confirm, {
        params: params,
    });
}
exports.confirmMeeting = confirmMeeting;
/* ==============================
    마이페이지 매칭 프로젝트 목록
============================== */
function getClientAllProjects(userNo, category, page) {
    return _1.client.get(urls.allProjects.client, {
        params: {
            clientNo: userNo,
            param: category,
            page: page,
        },
    });
}
exports.getClientAllProjects = getClientAllProjects;
function getPartnerAllProjects(userNo, category, page) {
    return _1.client.get(urls.allProjects.partner, {
        params: {
            userNo: userNo,
            param: category,
            page: page,
        },
    });
}
exports.getPartnerAllProjects = getPartnerAllProjects;
/* ==============================
    마이페이지 매칭 프로젝트 상세
============================== */
function getClientProjectDetail(userNo, projectNo, type) {
    return _1.client.get(urls.projectDetail.client, {
        params: {
            clientNo: userNo,
            projectNo: projectNo,
            type: type,
        },
    });
}
exports.getClientProjectDetail = getClientProjectDetail;
function getPartnerProjectDetail(userNo, projectNo, type) {
    return _1.client.get(urls.projectDetail.partner, {
        params: {
            partnerNo: userNo,
            projectNo: projectNo,
            type: type,
        },
    });
}
exports.getPartnerProjectDetail = getPartnerProjectDetail;
function offerAccept(params) {
    return _1.client.get(urls.replyOffer, {
        params: {
            type: 1,
            ...params,
        },
    });
}
exports.offerAccept = offerAccept;
function offerReject(params) {
    return _1.client.get(urls.replyOffer, {
        params: {
            type: 2,
            ...params,
        },
    });
}
exports.offerReject = offerReject;
/* ==============================
    매칭 견적서
============================== */
function requestQuotation(partnerNo, projectNo) {
    return _1.client.get(urls.quotation.request, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
        },
    });
}
exports.requestQuotation = requestQuotation;
function registerQuotation(params) {
    return _1.client.post(urls.quotation.register, params);
}
exports.registerQuotation = registerQuotation;
function delQuotation(partnerNo, projectNo, fileName) {
    return _1.client.get(urls.quotation.del, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
            s3FileName: fileName,
        },
    });
}
exports.delQuotation = delQuotation;
/* ==============================
    매칭 계약 확정
============================== */
function confirmContract(partnerNo, projectNo) {
    return _1.client.get(urls.confirmContract.client, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
        },
    });
}
exports.confirmContract = confirmContract;
/* ==============================
    매칭 계약서
============================== */
function requestContract(partnerNo, projectNo) {
    return _1.client.get(urls.contract.request, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
        },
    });
}
exports.requestContract = requestContract;
function registerContract(params) {
    return _1.client.post(urls.contract.register, params);
}
exports.registerContract = registerContract;
function delContract(partnerNo, projectNo, fileName) {
    return _1.client.get(urls.contract.del, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
            s3FileName: fileName,
        },
    });
}
exports.delContract = delContract;
/* ==============================
    내 포트폴리오 가져오기(파트너별 포트폴리오)
============================== */
function getPartnerPortfolios(partnerNo, page) {
    return _1.client.get(urls.portfolioList, {
        params: {
            partnerNo: partnerNo,
            page: page,
        },
    });
}
exports.getPartnerPortfolios = getPartnerPortfolios;
