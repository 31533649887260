"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainSection = exports.styledScollbar = exports.nonScrollbar = exports.boxEllipsis = exports.inlineEllipsis = exports.customCss = exports.cssValue = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const cssValue = (value) => (typeof value === 'string' ? value : value + 'px');
exports.cssValue = cssValue;
const customCss = (cssObj) => (0, styled_components_1.css) `
  ${cssObj}
`;
exports.customCss = customCss;
const inlineEllipsis = (height) => (0, styled_components_1.css) `
  display: inline-block;
  height: ${height}px;
  line-height: ${height}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
exports.inlineEllipsis = inlineEllipsis;
const boxEllipsis = (height, line) => (0, styled_components_1.css) `
  display: -webkit-box;
  height: ${height}px;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${line};
  -webkit-box-orient: vertical;
`;
exports.boxEllipsis = boxEllipsis;
exports.nonScrollbar = (0, styled_components_1.css) `
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
`;
exports.styledScollbar = (0, styled_components_1.css) `
  padding-right: 4px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    width: 8px;
    background-color: transparent;
    padding-left: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    border: 2px solid transparent;
    border-radius: 4px;
    background-color: #d9d9d9;
    background-clip: padding-box;
  }
`;
exports.MainSection = styled_components_1.default.main `
  position: relative;
  width: 100%;
  max-width: 1080px;
  padding: 0 16px;
  margin: 0 auto;

  @media (${({ theme }) => theme.media.tablet}) {
    padding: 0 16px 80px;
  }
`;
