"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RowNavigation = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const utils_1 = require("../../../themes/utils");
const inlineCss = (0, styled_components_1.css) `
  border-bottom: 1px solid ${(props) => props.theme.colors.gray2};

  li {
    position: relative;
    width: auto;

    a,
    button {
      color: ${(props) => props.theme.colors.gray6};
      white-space: nowrap;

      &.active {
        font-weight: ${(props) => props.theme.fontWeights.bold};
        color: ${(props) => props.theme.colors.gray9};
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #000;
        }
      }
    }
  }

  @media (${(props) => props.theme.media.tablet}) {
    z-index: 30;
    position: fixed;
    top: 56px;
    height: 48px;
    left: 0;
    width: 100%;
  }
`;
const inlineLCss = (0, styled_components_1.css) `
  ${inlineCss}

  ul {
    gap: 20px;

    li {
      padding: 14px 0;

      a,
      button {
        font-size: ${(props) => props.theme.fontSizes.xl};
      }
    }
  }

  @media (${(props) => props.theme.media.tablet}) {
    ul {
      gap: 8px;

      li {
        padding: 12px 16px;

        a,
        button {
          font-size: ${(props) => props.theme.fontSizes.s};
        }
      }
    }
  }
`;
const blockCss = (0, styled_components_1.css) `
  ul {
    padding: 10px 0;

    li {
      width: calc(14.2% - 7.7px);
      flex: 1;
      border-radius: 4px;
      padding: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);

      a,
      button {
        width: 100%;
        height: 100%;
        padding: 16px;
        color: ${(props) => props.theme.colors.gray8};
        white-space: pre-wrap;
      }
    }
  }

  @media (${(props) => props.theme.media.tablet}) {
    width: calc(100% + 32px);
    transform: translateX(-16px);
    ul {
      padding: 10px 16px;

      li {
        width: 90px;
        padding: 0;
        flex: auto;
      }
    }
  }
`;
exports.RowNavigation = styled_components_1.default.div `
  display: flex;
  overflow: show;
  width: 100%;
  background-color: #fff;

  ul {
    display: flex;
    gap: 8px;
    width: 100%;
    flex-wrap: nowrap;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 16px;
      text-align: center;

      a,
      button {
        font-size: ${(props) => props.theme.fontSizes.s};
        font-weight: ${(props) => props.theme.fontWeights.medium};
      }
    }
  }

  @media (${(props) => props.theme.media.tablet}) {
    overflow: scroll;
    ${utils_1.nonScrollbar}

    ul {
      width: auto;
    }
  }

  ${(props) => props.styleTheme === 'block' && blockCss};
  ${(props) => props.styleTheme === 'inline' && inlineCss};
  ${(props) => props.styleTheme === 'inlineL' && inlineLCss};
`;
