"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Display_1 = require("../../../components/ui/Display");
const style_1 = require("../../../components/ui/Button/style");
const react_router_dom_1 = require("react-router-dom");
const alert_1 = require("../../../state/alert");
const ProjectCard = __importStar(require("../../../components/feature/ProjectCard"));
const navigation_1 = require("../../../schemes/navigation");
const App_1 = require("../../../App");
const recoil_1 = require("recoil");
const popup_1 = require("../../../state/popup");
const mypage_1 = require("../../../state/mypage");
const text_1 = require("../../../utils/text");
/*
    - 프로젝트 현황
    - [사용] 프로젝트 매칭 현황 보기 위해 사용
    - [적용] 마이페이지 메인 - 매칭 중인 프로젝트 목록(프로젝트 현황 목록)
*/
function ProjectStateContainer({ user, data }) {
    const isClient = user.type === 'client';
    const isTabletSmall = (0, react_1.useContext)(App_1.ScreenContext).tabletSmall;
    const setApplyForm = (0, recoil_1.useSetRecoilState)(popup_1.applyPopupState);
    const setMeetingConfrim = (0, recoil_1.useSetRecoilState)(popup_1.MeetingConfirmPopupState);
    const resetMyMainPage = (0, recoil_1.useResetRecoilState)(mypage_1.myMainPageState);
    const navigate = (0, react_router_dom_1.useNavigate)();
    const { textAlert, actionAlert } = (0, alert_1.useAlert)();
    /* ==============================
        지원자 확인
    ============================== */
    function checkApplyPartners(projectNo) {
        navigate(`/mypage/client/applys/${projectNo}`);
    }
    ;
    /* ==============================
        지원서 확인
    ============================== */
    function checkApplyForm(projectNo) {
        setApplyForm({
            partnerNo: user.no,
            projectNo: projectNo
        });
    }
    ;
    /* ==============================
        ipm 이동
    ============================== */
    function goIpms(projectNo) {
        navigate(`/ipms/${user.type}/${projectNo}`);
    }
    ;
    /* ==============================
        진행상세 이동
    ============================== */
    function goDetails(state, projectNo) {
        const cateogory = (isClient
            ? navigation_1.CLIENT_MYPROJECT_NAVIGATION.filter(item => item.state === state)[0].name
            : navigation_1.PARTNER_MYPROJECT_NAVIGATION.filter(item => item.state === state)[0].name)
            ?? '전체';
        navigate({
            pathname: `/mypage/${user.type}/projects`,
            search: (0, react_router_dom_1.createSearchParams)({
                step: cateogory,
                no: projectNo.toString()
            }).toString()
        });
    }
    ;
    function getBtnProps(STEP, data) {
        const result = {
            title: data.title,
            state: '',
            infos: [],
            button: {
                text: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}),
                disable: false,
                action: ((any) => { })
            }
        };
        if (isClient) {
            switch (STEP) {
                case '모집중':
                    result.button.text = '지원자 상세';
                    result.button.action = () => { checkApplyPartners(data.projectNo); };
                    if (isTabletSmall) {
                        result.infos.push([STEP]);
                        result.infos.push([`모집 기간: ${data.recrStartDate?.substring(0, 10)} ~ ${data.recrEndDate?.substring(0, 10)}`]);
                    }
                    else {
                        result.infos.push([
                            STEP,
                            `모집 기간: ${data.recrStartDate?.substring(0, 10)} ~ ${data.recrEndDate?.substring(0, 10)}`
                        ]);
                    }
                    break;
                case '미팅 진행':
                    result.button.text = '진행 상세';
                    result.button.action = () => { goDetails(STEP, data.projectNo); };
                    if (isTabletSmall) {
                        result.infos.push([STEP]);
                        result.infos.push([
                            `요청: ${data.meetingReqeustNum}`,
                            `예정: ${data.meetingBeforeNum}`,
                            `완료: ${data.meetingAfterNum}`
                        ]);
                    }
                    else {
                        result.infos.push([
                            STEP,
                            `요청: ${data.meetingReqeustNum}`,
                            `예정: ${data.meetingBeforeNum}`,
                            `완료: ${data.meetingAfterNum}`
                        ]);
                    }
                    break;
                case '견적 진행':
                    result.button.text = '진행 상세';
                    result.button.action = () => { goDetails(STEP, data.projectNo); };
                    result.infos.push([STEP, `${data.estimateingNum}건`]);
                    break;
                case '계약 진행':
                    result.button.text = '진행 상세';
                    result.button.action = () => { goDetails(STEP, data.projectNo); };
                    result.infos.push([STEP, `${data.contractingNum}건`]);
                    break;
                case '진행중':
                    result.button.text = 'i-PMS';
                    result.infos.push([STEP, `진행 기간: ${data.estimatedPeriod}`]);
                    result.button.action = () => { goIpms(data.projectNo); };
                    break;
                case '매칭 완료':
                    result.button.text = 'i-PMS';
                    if (isTabletSmall) {
                        result.infos.push([STEP]);
                        result.infos.push([`프로젝트 완료: ${data.recrEndDate?.substring(0, 10)}`]);
                    }
                    else {
                        result.infos.push([STEP, `프로젝트 완료: ${data.recrEndDate?.substring(0, 10)}`]);
                    }
                    result.button.action = () => { goIpms(data.projectNo); };
                    break;
            }
        }
        else {
            switch (STEP) {
                case '지원중':
                    result.button.text = data.supportCheck === 1 ? '지원서 확인' : '진행 상세';
                    result.button.action = () => {
                        data.supportCheck === 1 ?
                            checkApplyForm(data.projectNo)
                            : goDetails(STEP, data.projectNo);
                    };
                    result.infos.push([
                        STEP,
                        `모집 기간: ${data.recrStartDate?.substring(0, 10)} ~ ${data.recrEndDate?.substring(0, 10)}`
                    ]);
                    break;
                case '제안 받음':
                    switch (data.meetingPrg) {
                        case 0:
                            result.button.text = '상태 선택';
                            break;
                        case 1:
                            result.button.text = '미팅 수락';
                            break;
                        case 2:
                            result.button.text = '미팅 거절';
                            break;
                        case 3:
                            result.button.text = '미팅 보류';
                            break;
                        default:
                            result.button.text = '미팅 요청';
                    }
                    result.button.action = () => {
                        if (data.meetingPrg === 0) {
                            setMeetingConfrim({
                                partnerNo: user.no,
                                clientNo: data.clientNo,
                                projectNo: data.projectNo,
                                callback: function () {
                                    resetMyMainPage();
                                }
                            });
                        }
                    };
                    result.button.disable = data.meetingPrg != 0;
                    result.infos.push([
                        STEP,
                        `미팅 방식: ${(0, text_1.dashString)(data.meetingMethod)}`,
                        `미팅 일시: ${(0, text_1.dashString)(data.meetingDate)} ${(0, text_1.dashString)(data.meetingTime)}`
                    ]);
                    if (data.meetingContent) {
                        result.infos.push([
                            `요청 사항: ${(0, text_1.dashString)(data.meetingContent.length > 20 ?
                                data.meetingContent.substring(0, 17) + '...'
                                : data.meetingContent)}`
                        ]);
                    }
                    break;
                case '미팅 진행':
                    result.button.text = data.supportCheck === 1 ? '지원서 확인' : '진행 상세';
                    result.button.action = () => {
                        data.supportCheck === 1 ?
                            checkApplyForm(data.projectNo)
                            : goDetails(STEP, data.projectNo);
                    };
                    if (data.meetingTime)
                        result.infos.push([STEP, `예정 일자: ${data.meetingTime?.substring(0, 10)}`]);
                    break;
                case '견적 진행':
                    result.button.text = '진행 상세';
                    result.button.action = () => { goDetails(STEP, data.projectNo); };
                    result.infos.push([STEP, `${data.estimateingNum}건`]);
                    break;
                case '계약 진행':
                    result.button.text = '진행 상세';
                    result.button.action = () => { goDetails(STEP, data.projectNo); };
                    result.infos.push([STEP, `${data.contractingNum}건`]);
                    break;
                case '진행중':
                    result.button.text = 'i-PMS';
                    result.infos.push([STEP, `진행 기간: ${data.estimatedPeriod}`]);
                    result.button.action = () => { goIpms(data.projectNo); };
                    break;
                case '매칭 완료':
                    result.button.text = 'i-PMS';
                    if (isTabletSmall) {
                        result.infos.push([STEP]);
                        result.infos.push([`프로젝트 완료: ${data.recrEndDate?.substring(0, 10)}`]);
                    }
                    else {
                        result.infos.push([STEP, `프로젝트 완료: ${data.recrEndDate?.substring(0, 10)}`]);
                    }
                    result.button.action = () => { goIpms(data.projectNo); };
                    break;
            }
        }
        return result;
    }
    ;
    return ((0, jsx_runtime_1.jsx)(Display_1.FlexCols, { _gap: 16, _m_gap: 8, children: data.map((item, idx) => {
            const STEP = item.projectStep;
            const PROPS = getBtnProps(STEP, item);
            const BTN_PROPS = PROPS.button;
            return ((0, jsx_runtime_1.jsx)(ProjectCard.State, { user: user, data: {
                    state: STEP,
                    infos: PROPS.infos,
                    title: item.projectName
                }, button: typeof (BTN_PROPS.text) === 'string' ?
                    (0, jsx_runtime_1.jsx)(style_1.DarkButton, { _width: (isTabletSmall && !isClient) ? '100%' : 80, _height: 42, _size: "xxs", disabled: BTN_PROPS.disable, onClick: e => { BTN_PROPS.action(); }, children: BTN_PROPS.text })
                    : BTN_PROPS.text }, idx));
        }) }));
}
exports.default = ProjectStateContainer;
