"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFullNumString = exports.stringToNum = exports.koreanWon = exports.koreanNumDash = exports.koreanNum = void 0;
function koreanNum(value) {
    switch (typeof value) {
        case 'number':
            return value.toLocaleString('ko-kr');
        case 'string':
            const stringVal = value.replaceAll(',', '');
            if (stringVal.length <= 0)
                return '';
            const numval = Number(stringVal);
            if (isNaN(numval))
                return '';
            else
                return numval.toLocaleString('ko-kr');
        default:
            return '';
    }
}
exports.koreanNum = koreanNum;
function koreanNumDash(value) {
    const result = koreanNum(value);
    return result.length > 0 ? result : '-';
}
exports.koreanNumDash = koreanNumDash;
function koreanWon(value, unit = 1) {
    const num = Number(typeof value === 'string' ? value.replaceAll(',', '') : value);
    if (isNaN(num))
        return '- 원';
    return koreanNumDash(num * unit) + '원';
}
exports.koreanWon = koreanWon;
function stringToNum(value) {
    switch (typeof value) {
        case 'number':
            return value;
        case 'string':
            const stringVal = value.replaceAll(',', '');
            const numval = Number(stringVal);
            if (isNaN(numval))
                return 0;
            else
                return numval;
        default:
            return 0;
    }
}
exports.stringToNum = stringToNum;
function getFullNumString(value, charLen) {
    if (charLen > value.length) {
        return new Array(charLen - value.length).fill(0).join('') + value;
    }
    else {
        return value;
    }
}
exports.getFullNumString = getFullNumString;
