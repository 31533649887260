"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const account_1 = require("../../../../service/client/account");
const user_1 = require("../../../../state/user");
// 데이터 파싱
const convertToData = (type) => {
    const data = getUrlParameter(type);
    const list = {};
    if (data !== '') {
        const items = (data.replace('{', '').replace('}', '')).split(', ');
        items.forEach(item => {
            const [key, value] = item.split('=');
            if (key == 'User(userNo')
                list['userNo'] = Number(value);
            else
                list[key] = value;
        });
    }
    return list;
};
// url 데이터
const getUrlParameter = (name) => {
    name = name.replace(/[\\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
function OAuth2RedirectHandler() {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const { user, login } = (0, user_1.useUser)();
    const userData = convertToData('userData');
    const authData = convertToData('authData');
    const isAuth = getUrlParameter('isAuth');
    const error = getUrlParameter('error');
    const oAuthRedirect = async () => {
        if (error) {
            return location.href = `/#/login`;
        }
        if (!authData) {
            return location.href = `/#/login`;
        }
        // 계정 연동
        if (user && authData) {
            const response = await (0, account_1.linkToAccount)(user.no.toString(), authData.provider, authData.providerId);
            const { code, message } = response.data;
            return location.href = `/#/mypage/account/user?message=${message}`;
        }
        // 로그인
        if (!user && JSON.parse(isAuth)) {
            login({ ...userData, userType: Number(userData.userType) });
        }
        // 회원가입 처리
        if (!user && !JSON.parse(isAuth)) {
            localStorage.setItem("auth_data", JSON.stringify(authData));
            return location.href = '/#/join';
        }
    };
    (0, react_1.useEffect)(() => {
        oAuthRedirect();
    }, []);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}));
}
exports.default = OAuth2RedirectHandler;
