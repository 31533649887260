"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const S = __importStar(require("../../../components/feature/Pms/style"));
const D = __importStar(require("../../../components/ui/Display"));
const style_1 = require("../../../components/ui/Text/style");
const alert_1 = require("../../../state/alert");
const Attachment_1 = require("../../../components/ui/Attachment");
function PmsCommentContainer({ data, handleDelete, }) {
    const [edit, setEdit] = (0, react_1.useState)(false);
    const { confirm } = (0, alert_1.useAlert)();
    return ((0, jsx_runtime_1.jsxs)(S.Comment, { children: [(0, jsx_runtime_1.jsx)("div", { className: `name name-${data.roleEn}`, children: data.name }), (0, jsx_runtime_1.jsxs)("div", { className: "contents", children: [(0, jsx_runtime_1.jsxs)("div", { className: "infos", children: [(0, jsx_runtime_1.jsxs)("ul", { className: "text", children: [(0, jsx_runtime_1.jsx)("li", { className: `title${data.chartMod ? ' accent' : ''}`, children: data.title }), typeof data.contents === 'string' ? ((0, jsx_runtime_1.jsx)("li", { children: data.contents })) : (data.contents.map((data, idx) => (0, jsx_runtime_1.jsx)("li", { children: data }, idx)))] }), (0, jsx_runtime_1.jsxs)("ul", { className: "metas", children: [(0, jsx_runtime_1.jsx)("li", { children: data.roleKo }), (0, jsx_runtime_1.jsxs)("li", { children: [data.createDate, " \uB4F1\uB85D"] }), data.deleteNum !== null && ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(style_1.Text, { as: "button", _size: "xxs", _color: "gray6", _decoration: "underline", onClick: () => {
                                                confirm({
                                                    text: '진행 업무 요약을 삭제하시겠습니까?',
                                                    onConfirm: () => {
                                                        handleDelete(data.deleteNum);
                                                    },
                                                    onCancel: () => { },
                                                });
                                            }, children: "\uC0AD\uC81C\uD558\uAE30" }) }))] })] }), data.files.length > 0 && ((0, jsx_runtime_1.jsx)(D.FlexRows, { _gap: "12px 16px", children: data.files.map((file, idx) => ((0, jsx_runtime_1.jsx)(Attachment_1.IconAttachment, { styleTheme: "border", url: file.s3Link + file.s3FileName, name: file.originalFileName }, idx))) }))] })] }));
}
exports.default = PmsCommentContainer;
