"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const recoil_1 = require("recoil");
const App_1 = require("../../../App");
const pms_1 = require("../../../state/pms");
const ui_1 = require("../../../state/ui");
const S = __importStar(require("./style"));
/*
    - GuideLayout
    - [사용] <body> overflow:scroll 에 의해 스크롤 되어야 하는 적응형 레이아웃
    - [적용] PMS 가이드(<PmsMainContainer>, <PmsRegisterContainer>) 에서 state로 값 전달,
    - 컴포넌트 위치는 <GlobalLayout>
    - *************************************
    - recoilState 통해 값 전달 받음
*/
function GuideLayout() {
    const location = (0, react_router_dom_1.useLocation)();
    const isTablet = (0, react_1.useContext)(App_1.ScreenContext).tablet;
    const pmsTour = (0, recoil_1.useRecoilValue)(pms_1.pmsTourState);
    const resetPmsTour = (0, recoil_1.useResetRecoilState)(pms_1.pmsTourState);
    const setPcHeaderUi = (0, recoil_1.useSetRecoilState)(ui_1.PcHeaderUiState);
    const resetPcHeaderUi = (0, recoil_1.useResetRecoilState)(ui_1.PcHeaderUiState);
    const [noMore, setNoMore] = (0, react_1.useState)(false);
    function setGuideStyleOn() {
        window.scrollTo(0, 0);
        document.body.style.height = '100%';
        document.body.style.overflowY = 'hidden';
        setPcHeaderUi({ hidden: true });
    }
    function setGuideStyleOff() {
        document.body.style.height = 'auto';
        document.body.style.overflowY = 'auto';
        resetPcHeaderUi();
    }
    (0, react_1.useEffect)(() => {
        if (pmsTour && !isTablet) {
            setGuideStyleOn();
        }
        else {
            setGuideStyleOff();
        }
    }, [pmsTour, isTablet]);
    (0, react_1.useEffect)(() => {
        setNoMore(false);
        return () => {
            setGuideStyleOff();
            resetPmsTour();
        };
    }, [location]);
    if (!pmsTour || isTablet)
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    else
        return ((0, jsx_runtime_1.jsx)(S.GuideOverlay, { children: (0, jsx_runtime_1.jsxs)(S.GuideInner, { children: [(0, jsx_runtime_1.jsx)("img", { className: "img", src: pmsTour.src, alt: pmsTour.alt }), (0, jsx_runtime_1.jsxs)(S.GuideBtnBox, { children: [(0, jsx_runtime_1.jsx)(S.GuideCheckBtn, { checked: noMore, onClick: () => {
                                    setNoMore((noMore) => !noMore);
                                }, children: "\uB2E4\uC2DC \uBCF4\uC9C0 \uC54A\uAE30" }), (0, jsx_runtime_1.jsx)(S.GuideCloseBtn, { onClick: () => {
                                    pmsTour.handleNext(noMore);
                                }, children: pmsTour.hasNext ? '다음' : '닫기' })] })] }) }));
}
exports.default = GuideLayout;
