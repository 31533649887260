"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTerms = exports.getNoticePost = exports.getNoticeList = void 0;
const index_1 = require("./index");
const urls = {
    terms: '/api/index/siteInfo',
    noticeList: '/api/board/notice',
    noticePost: '/api/board/noticeDetail',
};
// 공지사항 목록
function getNoticeList(page, keyword) {
    return index_1.client.get(urls.noticeList, {
        params: {
            page: page,
            content: keyword,
        },
    });
}
exports.getNoticeList = getNoticeList;
// 공지사항 상세
function getNoticePost(postNo) {
    return index_1.client.get(urls.noticePost, {
        params: {
            noticeNo: postNo,
        },
    });
}
exports.getNoticePost = getNoticePost;
// 이용약관
function getTerms() {
    return index_1.client.get(urls.terms);
}
exports.getTerms = getTerms;
