"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFormData = exports.getLastSteps = exports.isDataFilled = exports.requiredValidation = exports.defaultProjectDataset = void 0;
const projectDescription = `[ 프로젝트 현재 상황 ]\n- 프로젝트 소개 :\n예시 ) 일반 고객을 대상으로 한 쇼핑몰입니다.\n\n- 프로젝트 진행 계획 :\n예시 ) 일주일 내로 디자인이 완료될 예정입니다.\n\n- 현재 준비 되어있는 내용 :\n예시 ) 러프한 기획서가 준비되어 있으며, 디자인은 내부 디자이너가 진행합니다.\n\n[ 세부 업무 내용 ]\n- 서비스 목적\n예시 ) 일반 고객들 대상의 쇼핑몰\n\n- 사용자 기능 리스트\n예시 ) 간편 회원가입, 게시판, 결제기능, 알림톡 발송 등\n\n-  관리자 기능 리스트\n예시 ) 회원 정보 조회 및 편집, 게시판 관리, 상품관리 등\n\n[ 참고 자료 ]\n- 유사 서비스 : \n\n[ 우대 사항 ]\n- 우대사항 :\n\n예시 ) 쇼핑몰 디자인 경험이 있는 디자이너 \n예시 ) 정부지원사업 경험이 있는 개발사`;
const defaultProjectDataset = (userNo) => ({
    userNo: userNo,
    contractType: '',
    processType: '',
    projectType: '',
    plan: '',
    attachments: [],
    bizType: '',
    teamType: [],
    preferType: [],
    techCtg: [],
    techManage: [],
    techDesign: [],
    techDev: [],
    techItems: [],
    devLanguage: [],
    devFramework: [],
    contact: '0',
    minBudget: '10000',
    maxBudget: '5000000',
    meetingType: '대면',
    duration: '7',
    payType: '대면',
    sido: '',
    gugun: '',
    coWork: '1인',
    coSido: '',
    coGugun: '',
    coTimeStartHour: '',
    coTimeStartMinute: '',
    coTimeEndHour: '',
    coTimeEndminute: '',
    projectName: '',
    description: projectDescription,
});
exports.defaultProjectDataset = defaultProjectDataset;
const stepFields = {
    step1: ['contractType'],
    step2: ['processType'],
    step3: ['projectType', 'plan', 'attachments'],
    step4: ['bizType', 'teamType', 'preferType'],
    step5: ['techCtg', 'techManage', 'techDesign', 'techDev', 'techItems', 'devLanguage', 'devFramework', 'contact'],
    step6: [
        'minBudget',
        'maxBudget',
        'meetingType',
        'duration',
        'payType',
        'sido',
        'gugun',
        'coWork',
        'coSido',
        'coGugun',
        'coTimeStartHour',
        'coTimeStartMinute',
        'coTimeEndHour',
        'coTimeEndminute',
        'projectName',
        'description',
    ],
};
const requiredValidationSet = {
    step1: [{ name: 'contractType', message: '계약 방식을 선택해 주세요.' }],
    step2: [{ name: 'processType', message: '진행 방식을 선택해 주세요.' }],
    step3Type1: [
        { name: 'projectType', message: '프로젝트 분류를 선택해 주세요.' },
        { name: 'plan', message: '기획 준비 단계를 선택해 주세요.' },
    ],
    step3Type2: [
        { name: 'projectType', message: '프로젝트 분류를 선택해 주세요.' },
        { name: 'plan', message: '기획 준비 단계를 선택해 주세요.' },
        { name: 'attachments', message: `정부 지원 사업이거나 상세 문서가 \n 있으시면 관련 문서를 첨부해 주세요.` },
    ],
    step4: [
        { name: 'bizType', message: '기업 형태를 선택해 주세요.' },
        { name: 'teamType', message: 'IT팀 유무를 선택해 주세요.' },
        { name: 'preferType', message: '선호 개발사 형태를 선택해 주세요.' },
    ],
    step5: [{ name: 'techItems', message: '원하는 기술 분야를 선택해 주세요.' }],
    step6Type1: [
        { name: 'maxBudget', message: '희망 예산을 선택해 주세요.' },
        { name: 'minBudget', message: '희망 예산을 선택해 주세요.' },
        { name: 'meetingType', message: '미팅 방식을 선택해 주세요.' },
        { name: 'duration', message: '프로젝트 완료 희망 기간을 선택해 주세요.' },
        { name: 'payType', message: '대금지급방식을 선택해 주세요.' },
        { name: 'sido', message: '지역을 선택해 주세요.' },
        { name: 'gugun', message: '지역을 선택해 주세요.' },
        { name: 'projectName', message: '프로젝트 명을 입력해 주세요.' },
        { name: 'description', message: '상세 내용을 입력해 주세요.' },
    ],
    step6Type2: [
        { name: 'maxBudget', message: '희망 예산을 선택해 주세요.' },
        { name: 'minBudget', message: '희망 예산을 선택해 주세요.' },
        { name: 'meetingType', message: '미팅 방식을 선택해 주세요.' },
        { name: 'duration', message: '프로젝트 완료 희망 기간을 선택해 주세요.' },
        { name: 'payType', message: '대금지급방식을 선택해 주세요.' },
        { name: 'coWork', message: '협업 여부를 선택해 주세요.' },
        { name: 'coSido', message: '근무 지역을 선택해 주세요.' },
        { name: 'coGugun', message: '근무 지역을 선택해 주세요.' },
        { name: 'coTimeStartHour', message: '근무 시작 시간을 입력해 주세요.' },
        { name: 'coTimeStartMinute', message: '근무 시작 시간을 입력해 주세요.' },
        { name: 'coTimeEndHour', message: '근무 종료 시간을 입력해 주세요.' },
        { name: 'coTimeEndMinute', message: '근무 종료 시간을 입력해 주세요.' },
        { name: 'projectName', message: '프로젝트 명을 입력해 주세요.' },
        { name: 'description', message: '상세 내용을 입력해 주세요.' },
    ],
};
function requiredValidation(step, option) {
    switch (step) {
        case 1:
            return requiredValidationSet['step1'];
        case 2:
            return requiredValidationSet['step2'];
        case 3:
            if ((option?.projectType && option?.projectType === '정부 지원 사업') ||
                (option?.plan && option?.plan === '상세문서')) {
                return requiredValidationSet['step3Type2'];
            }
            else {
                return requiredValidationSet['step3Type1'];
            }
        case 4:
            return requiredValidationSet['step4'];
        case 5:
            return requiredValidationSet['step5'];
        case 6:
            if (option?.contractType && option.contractType === '도급') {
                return requiredValidationSet['step6Type1'];
            }
            else {
                return requiredValidationSet['step6Type2'];
            }
        default:
            return [];
    }
}
exports.requiredValidation = requiredValidation;
function isDataFilled(data) {
    const dataType = typeof data;
    switch (dataType) {
        case 'string':
            return data.length > 0;
        case 'object':
            if (Array.isArray(data))
                return data.length > 0;
            else
                return Object.keys(data).length > 0;
        default:
            return data !== null && data !== undefined;
    }
}
exports.isDataFilled = isDataFilled;
const getLastSteps = (dataset) => {
    let lastStep = Object.keys(stepFields).length;
    dataset = {
        ...dataset,
        techItems: dataset.techManage.length > 0 || dataset.techDesign.length > 0 || dataset.techDev.length > 0
            ? dataset.techCtg
            : [],
    };
    for (let step = 1; step <= lastStep; step++) {
        let requireds = requiredValidation(step, {
            contractType: dataset.contractType,
            projectType: dataset.projectType,
            plan: dataset.plan,
        });
        for (let requied of requireds) {
            if (!isDataFilled(dataset[requied.name]))
                return step;
        }
    }
    return lastStep;
};
exports.getLastSteps = getLastSteps;
function getFormData(user, dataset, files) {
    const isStayWork = dataset.contractType === '상주';
    const stringData = {
        userNo: user.no,
        projectProcess: dataset.contractType,
        projectSituation: dataset.processType,
        projectClass: dataset.projectType,
        projectPlan: dataset.plan,
        corporateType: dataset.bizType,
        itTeamStatus: dataset.teamType.toString(),
        favorCorporation: dataset.preferType.toString(),
        wantTechnologyCtg: dataset.techCtg[0] === '전체' ? '기획,디자인,개발' : dataset.techCtg.toString(),
        wantTechnologyPlan: dataset.techManage.toString().replace('전체,', ''),
        wantTechnologyDesign: dataset.techDesign.toString().replace('전체,', ''),
        wantTechnologyDev: dataset.techDev.toString().replace('전체,', ''),
        wantDevelop: dataset.devLanguage.toString(),
        wantFramework: dataset.devFramework.toString(),
        csStatus: dataset.contact,
        minPrice: dataset.minBudget.replaceAll(',', ''),
        maxPrice: dataset.maxBudget.replaceAll(',', ''),
        meeting: dataset.meetingType,
        estimatedPeriod: dataset.duration + '일',
        paymentMethod: dataset.payType,
        siDoArea: dataset.sido,
        guGunArea: dataset.gugun,
        projectName: dataset.projectName,
        projectContent: dataset.description,
        ...(isStayWork && {
            workStartTime: `${dataset.coTimeStartHour}:${dataset.coTimeStartMinute}`,
            workEndTime: `${dataset.coTimeEndHour}:${dataset.coTimeEndMinute}`,
            workingArea1: dataset.coSido,
            workingArea2: dataset.coGugun,
            numberCollaborators: dataset.coWork,
        }),
    };
    const formData = new FormData();
    for (let file of files)
        formData.append('multipartFiles', file);
    const jsonData = new Blob([JSON.stringify(stringData)], { type: 'application/json' });
    formData.append('project', jsonData);
    return formData;
}
exports.getFormData = getFormData;
