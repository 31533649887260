"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pmsManager = exports.pmsGuideOff = exports.pmsGuideOffState = exports.pmsTourState = exports.pmsScheduleData = exports.pmsScheduleState = void 0;
const recoil_1 = require("recoil");
const _1 = require(".");
exports.pmsScheduleState = (0, recoil_1.atom)({
    key: _1.stateKeys.pmsSchedule,
    default: null,
});
exports.pmsScheduleData = (0, recoil_1.selectorFamily)({
    key: `${_1.stateKeys.pmsSchedule}_DATA`,
    get: (projectNo) => ({ get }) => {
        const savedData = get(exports.pmsScheduleState);
        if (savedData && savedData.projectNo === projectNo)
            return savedData;
        return null;
    },
    set: (projectNo) => ({ set }, newValue) => {
        set(exports.pmsScheduleState, newValue);
    },
});
exports.pmsTourState = (0, recoil_1.atom)({
    key: _1.stateKeys.pmsTour,
    default: null,
});
const guideStorageEffect = () => ({ setSelf, onSet }) => {
    const savedLocalValue = localStorage.getItem(_1.stateKeys.pmsGuide)
        ? JSON.parse(localStorage.getItem(_1.stateKeys.pmsGuide))
        : {};
    const savedSessionValue = sessionStorage.getItem(_1.stateKeys.pmsGuide)
        ? JSON.parse(sessionStorage.getItem(_1.stateKeys.pmsGuide))
        : {};
    let offs = {};
    for (const userIdKey in savedLocalValue) {
        for (const projectNoKey in savedLocalValue[userIdKey]) {
            offs = {
                ...offs,
                [userIdKey]: {
                    ...(offs[userIdKey] ?? {}),
                    [projectNoKey]: {
                        local: savedLocalValue[userIdKey][projectNoKey],
                        session: [],
                    },
                },
            };
        }
    }
    for (const userIdKey in savedSessionValue) {
        for (const projectNoKey in savedSessionValue[userIdKey]) {
            const userValue = offs[userIdKey] ?? {};
            const projectValue = userValue[projectNoKey] ?? { local: [] };
            offs = {
                ...offs,
                [userIdKey]: {
                    ...userValue,
                    [projectNoKey]: {
                        ...projectValue,
                        session: savedSessionValue[userIdKey][projectNoKey],
                    },
                },
            };
        }
    }
    setSelf(offs);
    onSet((newValue) => {
        let sessionValue = {};
        let localValue = {};
        for (const userIdKey in newValue) {
            const userValue = newValue[userIdKey];
            for (const projectNoKey in userValue) {
                sessionValue = {
                    ...sessionValue,
                    [userIdKey]: {
                        ...(sessionValue[userIdKey] ?? {}),
                        [projectNoKey]: userValue[projectNoKey].session,
                    },
                };
                localValue = {
                    ...localValue,
                    [userIdKey]: {
                        ...(localValue[userIdKey] ?? {}),
                        [projectNoKey]: userValue[projectNoKey].local,
                    },
                };
            }
        }
        sessionStorage.setItem(_1.stateKeys.pmsGuide, JSON.stringify(sessionValue));
        localStorage.setItem(_1.stateKeys.pmsGuide, JSON.stringify(localValue));
    });
};
exports.pmsGuideOffState = (0, recoil_1.atom)({
    key: _1.stateKeys.pmsGuide,
    default: {
        '': {
            0: {
                session: [],
                local: [],
            },
        },
    },
    effects: [guideStorageEffect()],
});
/*
    - params: [category, userId, projectNo]
*/
exports.pmsGuideOff = (0, recoil_1.selectorFamily)({
    key: `_${_1.stateKeys.pmsGuide}`,
    get: (param) => ({ get }) => {
        const category = param[0];
        const userId = param[1];
        const projectNo = param[2];
        const savedValue = get(exports.pmsGuideOffState)[userId];
        return {
            sessionOff: savedValue && savedValue[projectNo] ? savedValue[projectNo].session.indexOf(category) >= 0 : false,
            localOff: savedValue && savedValue[projectNo] ? savedValue[projectNo].local.indexOf(category) >= 0 : false,
        };
    },
    set: (param) => ({ set, get }, newValue) => {
        const category = param[0];
        const userId = param[1];
        const projectNo = param[2];
        const saved = get(exports.pmsGuideOffState);
        const savedValue = saved[userId] ?? {};
        newValue = newValue;
        let categoryValue = savedValue[projectNo] ?? { session: [], local: [] };
        if (newValue.sessionOff && categoryValue.session.indexOf(category) < 0) {
            categoryValue = {
                ...categoryValue,
                session: [...categoryValue.session, category],
            };
        }
        if (newValue.localOff && categoryValue.local.indexOf(category) < 0) {
            categoryValue = {
                ...categoryValue,
                local: [...categoryValue.local, category],
            };
        }
        const newOff = {
            ...saved,
            [userId]: {
                ...savedValue,
                [projectNo]: categoryValue,
            },
        };
        set(exports.pmsGuideOffState, newOff);
    },
});
const pmsAdminState = (0, recoil_1.atom)({
    key: _1.stateKeys.pmsManager,
    default: null,
    effects: [(0, _1.sessionStorageEffect)({ key: _1.stateKeys.pmsManager, hour: 2 })],
});
exports.pmsManager = (0, recoil_1.selectorFamily)({
    key: `_${_1.stateKeys}`,
    get: (projectNo) => ({ get }) => {
        const value = get(pmsAdminState);
        if (value && value.projectNo === projectNo)
            return value;
        return null;
    },
    set: (projectNo) => ({ set }, newValue) => {
        set(pmsAdminState, newValue);
    },
});
