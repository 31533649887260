"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PcHeaderUiState = void 0;
const recoil_1 = require("recoil");
const _1 = require(".");
exports.PcHeaderUiState = (0, recoil_1.atom)({
    key: _1.stateKeys.pcHeaderUi,
    default: {
        hidden: false,
    },
});
