"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCertificationData = exports.getCertificationFormData = exports.certificationDataset = void 0;
const projectCategory_1 = require("../schemes/projectCategory");
const file_1 = require("../utils/file");
const number_1 = require("../utils/number");
exports.certificationDataset = {
    authCheck: -1,
    bizType: '',
    companyName: '',
    repName: '',
    sido: '',
    gugun: '',
    bizDoc: [],
    accDoc: [],
    insDoc: [],
    etcDoc: [],
    techCtg: [],
    techManage: [],
    techDesign: [],
    techDev: [],
    devLanguage: [],
    selfDevLanguage: [],
    devFramework: [],
    selfDevFramework: [],
};
function getCertificationFormData(user, data) {
    const formData = new FormData();
    const isPartnerUser = user.type === 'client';
    const stringData = user.type === 'client'
        ? {
            authCheck: data.authCheck,
            userNo: user.no,
            userType: isPartnerUser ? 2 : 1,
            corporateCtg: data.bizType,
            companyName: data.companyName,
            representativeName: data.repName,
            siDo: data.sido,
            guGun: data.gugun,
        }
        : {
            authCheck: data.authCheck,
            userNo: user.no,
            userType: isPartnerUser ? 2 : 1,
            corporateCtg: data.bizType,
            companyName: data.companyName,
            representativeName: data.repName,
            siDo: data.sido,
            guGun: data.gugun,
            wantDevelop: [...data.devLanguage, ...data.selfDevLanguage].toString(),
            wantFramework: [...data.devFramework, ...data.selfDevFramework].toString(),
            wantTechnologyCtg: data.techCtg.toString(),
            wantTechnologyDesign: data.techDesign.toString(),
            wantTechnologyDev: data.techDev.toString(),
            wantTechnologyPlan: data.techManage.toString(),
            residePossible: data.residePossible,
            planner: data.bizType !== '프리랜서' && data.planner.length > 0 ? (0, number_1.stringToNum)(data.planner) : 0,
            designer: data.bizType !== '프리랜서' && data.designer.length > 0 ? (0, number_1.stringToNum)(data.designer) : 0,
            publisher: data.bizType !== '프리랜서' && data.publisher.length > 0 ? (0, number_1.stringToNum)(data.publisher) : 0,
            developer: data.bizType !== '프리랜서' && data.developer.length > 0 ? (0, number_1.stringToNum)(data.developer) : 0,
        };
    if (data.bizDoc.length > 0)
        for (let file of data.bizDoc)
            formData.append('businessLicense', file);
    if (data.accDoc.length > 0)
        for (let file of data.accDoc)
            formData.append('copyAccount', file);
    if (data.insDoc.length > 0)
        for (let file of data.insDoc)
            formData.append('insuranceList', file);
    if (data.etcDoc.length > 0)
        for (let file of data.etcDoc)
            formData.append('otherAttachments', file);
    formData.append('param', new Blob([JSON.stringify(stringData)], { type: 'application/json' }));
    return formData;
}
exports.getCertificationFormData = getCertificationFormData;
async function getCertificationData(data) {
    const { authCheck, companyName, corporateCtg, guGun, representativeName, siDo, companyfiles, wantDevelop, wantFramework, wantTechnologyCtg, wantTechnologyDesign, wantTechnologyDev, wantTechnologyPlan, residePossible, planner, designer, publisher, developer, } = data;
    const devLanguageArr = (0, projectCategory_1.getOriginAndSelfOptions)('devLanguage', wantDevelop);
    const devFrameworkArr = (0, projectCategory_1.getOriginAndSelfOptions)('devFramework', wantFramework);
    const isNotCertified = authCheck === 0;
    const companyData = {
        authCheck: isNaN(authCheck) ? -1 : authCheck,
        bizType: corporateCtg || '',
        companyName: companyName || '',
        repName: representativeName || '',
        sido: !siDo || siDo === '미인증' ? '' : siDo,
        gugun: !guGun || guGun === '미인증' ? '' : guGun,
        bizDoc: [],
        accDoc: [],
        insDoc: [],
        etcDoc: [],
        techCtg: wantTechnologyCtg ? wantTechnologyCtg.split(',') : [],
        techManage: wantTechnologyPlan ? wantTechnologyPlan.split(',') : [],
        techDesign: wantTechnologyDesign ? wantTechnologyDesign.split(',') : [],
        techDev: wantTechnologyDev ? wantTechnologyDev.split(',') : [],
        devLanguage: devLanguageArr.origin,
        selfDevLanguage: devLanguageArr.self,
        devFramework: devFrameworkArr.origin,
        selfDevFramework: devFrameworkArr.self,
        residePossible: residePossible ?? '',
        planner: isNotCertified && planner <= 0 ? '' : (0, number_1.koreanNum)(planner),
        designer: isNotCertified && designer <= 0 ? '' : (0, number_1.koreanNum)(designer),
        publisher: isNotCertified && publisher <= 0 ? '' : (0, number_1.koreanNum)(publisher),
        developer: isNotCertified && developer <= 0 ? '' : (0, number_1.koreanNum)(developer),
    };
    if (companyfiles && companyfiles.length > 0) {
        for (let file of companyfiles) {
            const data = await (0, file_1.fileFromUrl)(file.s3Link + file.s3FileName, file.originalFileName);
            switch (file.fileType) {
                case 1:
                    companyData.bizDoc.push(data);
                    break;
                case 2:
                    companyData.accDoc.push(data);
                    break;
                case 3:
                    companyData.insDoc.push(data);
                    break;
                case 4:
                    companyData.etcDoc.push(data);
                    break;
                default:
                    break;
            }
        }
    }
    return companyData;
}
exports.getCertificationData = getCertificationData;
