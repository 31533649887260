"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPmsCommtent = exports.getPmsTaskFormData = exports.getPmsChartTasks = exports.getPmsFormTasks = exports.defaultPmsScheduleTasks = exports.defaultPmsFormTasks = exports.pmsRole = void 0;
const date_1 = require("../utils/date");
const text_1 = require("../utils/text");
function pmsRole(role) {
    if (role == 'partner' || role == 'client' || role == 'manager')
        return role.toUpperCase();
    return null;
}
exports.pmsRole = pmsRole;
const TODAY = new Date();
exports.defaultPmsFormTasks = [
    {
        title: '',
        start: null,
        end: null,
        isMain: true,
        error: '',
        subTasks: [
            {
                title: '',
                start: null,
                end: null,
                isMain: false,
                error: '',
            },
        ],
    },
];
exports.defaultPmsScheduleTasks = [
    {
        title: '작업 일정을 추가해보세요.',
        start: null,
        end: null,
        isMain: true,
        rate: 0,
        len: 0,
        subTasks: [
            {
                title: '세부일정을 추가해보세요.',
                start: null,
                end: null,
                isMain: false,
                rate: 0,
                len: 0,
            },
        ],
    },
];
function getPmsTask(props) {
    const { data, type } = props;
    const isMain = data.pmsSubNo === null;
    const pmsNo = isMain ? data.pmsMainNo : data.pmsSubNo;
    const startDateVal = data[isMain ? 'mainStartDate' : 'subStartDate'];
    const endDateVal = data[isMain ? 'mainEndDate' : 'subEndDate'];
    const startDate = startDateVal ? new Date(startDateVal) : null;
    const endDate = endDateVal ? new Date(endDateVal) : null;
    const pmsTask = {
        title: data[isMain ? 'mainCategory' : 'subCategory'],
        start: startDate,
        end: endDate,
        isMain: isMain,
    };
    const len = startDate && endDate ? (0, date_1.getDateLength)(startDate, endDate) : 0;
    if (type === 'chart') {
        let rate = 0;
        if (startDate && endDate) {
            if (endDate <= TODAY) {
                rate = 100;
            }
            else if (startDate > TODAY) {
                rate = 0;
            }
            else {
                rate = Math.round((((0, date_1.getDateLength)(startDate, TODAY) + 1) / (len + 1)) * 100);
            }
        }
        return {
            ...pmsTask,
            len: len,
            rate: rate,
        };
    }
    if (type === 'form') {
        return {
            ...pmsTask,
            error: '',
            [isMain ? 'pmsMainNo' : 'pmsSubNo']: pmsNo,
        };
    }
}
function getPmsTasks(props) {
    const { data, type } = props;
    if (!data || data.length <= 0) {
        if (type === 'chart')
            return exports.defaultPmsScheduleTasks;
        if (type === 'form')
            return exports.defaultPmsFormTasks;
    }
    let result;
    if (type === 'chart')
        result = [];
    if (type === 'form')
        result = [];
    result = data.map((mainData) => {
        const mainTask = getPmsTask({ type: type, data: mainData });
        if (mainData.subList) {
            const subTasks = mainData.subList.map((subData) => getPmsTask({ type: type, data: subData }));
            const averageSum = Math.round(subTasks.reduce((acc, item) => acc + item.rate, 0) / subTasks.length);
            const mainSum = isNaN(averageSum) || averageSum <= 0 ? 0 : averageSum > 100 ? 100 : averageSum;
            return {
                ...mainTask,
                rate: mainSum,
                subTasks: subTasks,
            };
        }
        else {
            return mainTask;
        }
    });
    return result;
}
function getPmsFormTasks(data) {
    return getPmsTasks({ type: 'form', data: data });
}
exports.getPmsFormTasks = getPmsFormTasks;
function getPmsChartTasks(data) {
    return getPmsTasks({ type: 'chart', data: data });
}
exports.getPmsChartTasks = getPmsChartTasks;
function getPmsTaskFormData(params) {
    let mainList = [];
    for (const task of params.data) {
        mainList.push({
            projectNo: params.projectNo,
            partnerNo: params.partnerNo,
            mainCategory: task.title,
            mainStartDate: task.start ? (0, date_1.stringDateFormDate)(task.start) : '',
            mainEndDate: task.end ? (0, date_1.stringDateFormDate)(task.end) : '',
            subList: task.subTasks
                ? task.subTasks.map((subTask) => ({
                    projectNo: params.projectNo,
                    partnerNo: params.partnerNo,
                    mainCategory: task.title,
                    subCategory: subTask.title,
                    subStartDate: subTask.start ? (0, date_1.stringDateFormDate)(subTask.start) : '',
                    subEndDate: subTask.end ? (0, date_1.stringDateFormDate)(subTask.end) : '',
                    ...(subTask.pmsSubNo && { pmsSubNo: subTask.pmsSubNo }),
                }))
                : [],
            ...(task.pmsMainNo && { pmsMainNo: task.pmsMainNo }),
        });
    }
    return {
        mainList: mainList,
    };
}
exports.getPmsTaskFormData = getPmsTaskFormData;
function getPmsCommtent(data) {
    const { adminId, contentsArr, contents, createDate, registerRole, registerName, title, file, pmsFixNum, pmsfixNo, deleteCheck, } = data;
    return {
        id: registerRole === 'PARTNER' ? '파트너' : registerRole === 'CLIENT' ? '클라이언트' : adminId,
        name: registerRole === 'MANAGER' ? '긱플러스' : (0, text_1.starString)(registerName, 1),
        roleEn: registerRole.toLowerCase(),
        roleKo: registerRole === 'PARTNER' ? '파트너' : registerRole === 'CLIENT' ? '클라이언트' : '관리자',
        title: title,
        contents: contentsArr ? contentsArr : (0, text_1.dashString)(contents),
        createDate: createDate ? createDate.substring(0, 16) : '',
        files: file ?? [],
        chartMod: pmsFixNum === 0,
        deleteNum: deleteCheck ? pmsfixNo : null,
    };
}
exports.getPmsCommtent = getPmsCommtent;
