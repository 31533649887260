"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGanttWeeks = exports.getGanttDates = void 0;
const date_1 = require("../../../../utils/date");
function getGanttDates(start, end) {
    const startDate = (0, date_1.getPrevMonDay)(start);
    const endDate = (0, date_1.getNextSunDay)(end);
    const dateLen = (0, date_1.getDateLength)(startDate, endDate);
    const dateArr = [];
    for (let i = 0; i <= dateLen; i++) {
        const date = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + i);
        const dateEl = {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            date: date.getDate(),
            day: date_1.KO_DAY[date.getDay()],
            weekend: date.getDay() < 1 || date.getDay() > 5,
            fullDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
            id: `d_${i}th`,
        };
        dateArr.push(dateEl);
    }
    return dateArr;
}
exports.getGanttDates = getGanttDates;
function getGanttWeeks(dates) {
    const weekArr = [];
    for (let i = 0; i < Math.ceil(dates.length / 7); i++) {
        const idx = i * 7;
        const week = {
            week: `${dates[idx].fullDate} ~ ${dates[idx + 6].fullDate}`,
            dates: dates.slice(idx, idx + 7),
        };
        weekArr.push(week);
    }
    return weekArr;
}
exports.getGanttWeeks = getGanttWeeks;
