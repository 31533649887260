"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.portfolioListData = exports.portfolioListState = exports.projectListState = exports.NoticeListState = exports.homePortfolioState = exports.homeProjectState = void 0;
const recoil_1 = require("recoil");
const _1 = require(".");
/* ==============================
    메인 페이지 목록
============================== */
exports.homeProjectState = (0, recoil_1.atom)({
    key: _1.stateKeys['homeProject'],
    default: null,
});
exports.homePortfolioState = (0, recoil_1.atom)({
    key: _1.stateKeys['homePortfolio'],
    default: null,
});
exports.NoticeListState = (0, recoil_1.atom)({
    key: _1.stateKeys['notice'],
    default: null,
});
/* ==============================
    프로젝트 찾기 목록
============================== */
exports.projectListState = (0, recoil_1.atom)({
    key: _1.stateKeys['projectList'],
    default: null,
});
exports.portfolioListState = (0, recoil_1.atom)({
    key: _1.stateKeys.portfolioList,
    default: null,
    // effects: [sessionStorageEffect({key: stateKeys.portfolioList})]
});
exports.portfolioListData = (0, recoil_1.selectorFamily)({
    key: _1.stateKeys.portfolioList + '_LIST',
    get: (url) => ({ get }) => {
        const saved = get(exports.portfolioListState);
        return saved;
        if (saved && saved.path === url) {
            return saved;
        }
        else {
            return null;
        }
    },
    set: (url) => ({ set, get }, newValue) => {
        set(exports.portfolioListState, newValue);
    },
});
// export const portfolioListHistory = selectorFamily({
//     key: stateKeys['portfolioList'] + '_POST',
//     get: (url: string) => ({get}) => {
//         // const saved = get(portfolioListState);
//         return get(portfolioListState);
//     },
//     set: (url: string) => ({set, get}, newValue) => {
//         const saved = get(portfolioListState);
//         if(saved) {
//             set(portfolioListState, {
//                 path: saved.path,
//                 currentPage: saved.currentPage,
//                 scrollY: saved.scrollY
//             })
//         }
//     }
// })
