"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.delNewProjectFile = exports.getNewProjectFile = exports.addNewProjetFile = exports.newProject = exports.applyProject = exports.checkProjectApply = exports.getProjectPost = exports.getProjectHomeList = exports.getProjectList = void 0;
const _1 = require(".");
const urls = {
    projectList: '/api/project/findProject',
    projectHomeList: '/api/index/projectList',
    projectPost: '/api/project/detail',
    applyProject: {
        check: '/api/project/supportCheck',
        form: '/api/project/support',
    },
    newProject: '/api/project/registration',
    newProjectFile: {
        add: '/api/project/unregister',
        get: '/api/project/loadFile',
    },
};
function getProjectList(params) {
    return _1.client.get(urls.projectList, { params: params });
}
exports.getProjectList = getProjectList;
// 홈 프로젝트 목록
function getProjectHomeList(userNo) {
    return _1.client.get(urls.projectHomeList, { params: { userNo: userNo } });
}
exports.getProjectHomeList = getProjectHomeList;
// 프로젝트 상세
function getProjectPost(userNo, postNo) {
    return _1.client.get(urls.projectPost, { params: { userNo: userNo, projectNo: postNo } });
}
exports.getProjectPost = getProjectPost;
/* ==============================
    프로젝트 지원
============================== */
// 프로젝트 지원 가능 여부 체크
function checkProjectApply(projectNo, partnerNo) {
    return _1.client.post(urls.applyProject.check, {
        projectNo: projectNo,
        partnerNo: partnerNo,
    });
}
exports.checkProjectApply = checkProjectApply;
function applyProject(params) {
    return _1.client.post(urls.applyProject.form, params);
}
exports.applyProject = applyProject;
/* ==============================
    프로젝트 등록
============================== */
// 프로젝트 등록
function newProject(params) {
    return _1.client.post(urls.newProject, params);
}
exports.newProject = newProject;
// 프로젝트 임시파일 저장
function addNewProjetFile(params) {
    return _1.client.post(urls.newProjectFile.add, params);
}
exports.addNewProjetFile = addNewProjetFile;
// 프로젝트 임시파일 불러오기
function getNewProjectFile(clientNo) {
    return _1.client.get(urls.newProjectFile.get, {
        params: {
            clientNo: clientNo,
            type: 1,
        },
    });
}
exports.getNewProjectFile = getNewProjectFile;
// 프로젝트 임시파일 삭제
function delNewProjectFile(clientNo) {
    return _1.client.get(urls.newProjectFile.get, {
        params: {
            clientNo: clientNo,
            type: 2,
        },
    });
}
exports.delNewProjectFile = delNewProjectFile;
