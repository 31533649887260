"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginManager = exports.deletePmsComment = exports.getPmsComments = exports.writeNewPmsComment = exports.getPmsSchedule = exports.writePmsSchedule = exports.registerPmsSchedule = void 0;
const _1 = require(".");
const urls = {
    read: '/api/pms/scheduleRead',
    register: '/api/pms/scheduleRegister',
    write: '/api/pms/scheduleWrite',
    admin: '/api/pms/admin/login',
    comments: {
        write: '/api/pms/workRegister',
        read: '/api/pms/pmsFix',
        delete: '/api/pms/workDelete',
    },
};
/* ==============================
    스케쥴 등록
============================== */
function registerPmsSchedule(parmas) {
    return _1.client.post(urls.register, parmas);
}
exports.registerPmsSchedule = registerPmsSchedule;
/* ==============================
    스케쥴 수정
============================== */
function writePmsSchedule(parmas) {
    return _1.client.post(urls.write, parmas);
}
exports.writePmsSchedule = writePmsSchedule;
function getPmsSchedule(params) {
    return _1.client.post(urls.read, params);
}
exports.getPmsSchedule = getPmsSchedule;
/* ==============================
    진행 업무 등록
============================== */
function writeNewPmsComment(params) {
    return _1.client.post(urls.comments.write, params);
}
exports.writeNewPmsComment = writeNewPmsComment;
function getPmsComments(params) {
    return _1.client.get(urls.comments.read, {
        params: params,
    });
}
exports.getPmsComments = getPmsComments;
/* ==============================
    진행 업무 삭제
============================== */
function deletePmsComment(deleteNo) {
    return _1.client.get(urls.comments.delete, {
        params: {
            PMSFixNo: deleteNo,
        },
    });
}
exports.deletePmsComment = deletePmsComment;
/* ==============================
    관리자 로그인
============================== */
function loginManager(id, pwd, projectNo) {
    return _1.client.post(urls.admin, {
        adminId: id,
        adminPwd: pwd,
        projectNo: projectNo,
    });
}
exports.loginManager = loginManager;
