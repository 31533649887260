"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.company = void 0;
exports.company = {
    name: '긱플러스',
    address: '경기도 군포시 군포첨단산업2로 22번길 5, 군포산업진흥원 5층 507호',
    businessCode: '899-11-02194',
    email: 'gigplus@support.com',
    customerService: {
        tel: '1688-6681',
    },
    privacyAgent: '이종인',
    represantative: '이종인',
};
