"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dashString = exports.commaString = exports.starString = void 0;
function starString(value, max) {
    if (typeof value !== 'string')
        return '';
    const starString = value
        .split('')
        .map((item, idx) => (idx > max - 1 ? '*' : item))
        .join('');
    return starString.length < 10 ? starString : starString.substring(0, 10);
}
exports.starString = starString;
function commaString(values) {
    return values.replaceAll(',', ', ');
}
exports.commaString = commaString;
const nullText = ['미지정', '미인증', '미선택'];
function dashString(value) {
    if (typeof value != 'string')
        return '-';
    if (nullText.indexOf(value) >= 0 || value.length <= 0)
        return '-';
    return commaString(value);
}
exports.dashString = dashString;
