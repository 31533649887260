"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getManagerData = void 0;
const text_1 = require("../../../utils/text");
function getManagerData(data) {
    return data.adminName && data.adminName.length > 0
        ? `${(0, text_1.dashString)(data.adminName)} (${(0, text_1.dashString)(data.adminPhone)})`
        : '-';
}
exports.getManagerData = getManagerData;
