"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.client = void 0;
const react_query_1 = require("@tanstack/react-query");
const client = () => new react_query_1.QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * (60 * 1000),
            cacheTime: 10 * (60 * 1000), // 10 min
        },
    },
    queryCache: new react_query_1.QueryCache({
        onError: (error) => {
            console.log('query-error: ', error);
        },
    }),
});
exports.client = client;
