"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadingColorDots = exports.LoadingDots = exports.LoadingSpinner = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const style_1 = require("./style");
const LoadingSpinner = () => ((0, jsx_runtime_1.jsxs)(style_1.Spinner, { children: [(0, jsx_runtime_1.jsx)("div", {}), (0, jsx_runtime_1.jsx)("div", {})] }));
exports.LoadingSpinner = LoadingSpinner;
const LoadingDots = () => ((0, jsx_runtime_1.jsxs)(style_1.Dots, { children: [(0, jsx_runtime_1.jsx)("div", {}), (0, jsx_runtime_1.jsx)("div", {}), (0, jsx_runtime_1.jsx)("div", {}), (0, jsx_runtime_1.jsx)("div", {})] }));
exports.LoadingDots = LoadingDots;
const LoadingColorDots = () => (0, jsx_runtime_1.jsx)(style_1.ColorDots, {});
exports.LoadingColorDots = LoadingColorDots;
