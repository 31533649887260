"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const constants_1 = require("./constants");
const S = __importStar(require("./style"));
const D = __importStar(require("../../../ui/Display"));
const utils_1 = require("./utils");
const date_1 = require("../../../../utils/date");
const d3 = __importStar(require("d3"));
const date_2 = require("../../../../utils/date");
function PmsGantt({ data, modDate }) {
    const ROW_CLASS = 'task_row';
    // ref
    const canvasRef = (0, react_1.useRef)(null);
    const todayRef = (0, react_1.useRef)(null);
    const bodyRef = (0, react_1.useRef)(null);
    // data
    const [ganttDates, setGanttDates] = (0, react_1.useState)([]);
    const [ganttWeeks, setGanttWeeks] = (0, react_1.useState)([]);
    const [ganttTasks, setGanttTasks] = (0, react_1.useState)([]);
    const [tooltipProps, setTooltipProps] = (0, react_1.useState)(null);
    // ui
    const [rowVisible, setRowVisible] = (0, react_1.useState)([]);
    const [todayFocus, setTodayFocus] = (0, react_1.useState)(false);
    function setGanttData(data) {
        const tasks = data;
        const { start, end } = tasks.reduce((acc, task) => {
            const tStart = task.start ? new Date(task.start) : acc.start;
            const tEnd = task.end ? new Date(task.end) : acc.end;
            return {
                start: acc.start > tStart ? tStart : acc.start,
                end: acc.end < tEnd ? tEnd : acc.end,
            };
        }, {
            start: date_1.TODAY,
            end: new Date(date_1.TODAY.getFullYear(), date_1.TODAY.getMonth() + 1, date_1.TODAY.getDate()),
        });
        const dates = (0, utils_1.getGanttDates)(start, end);
        const weeks = (0, utils_1.getGanttWeeks)(dates);
        const visible = new Array(tasks.length).fill(true);
        setGanttDates(dates);
        setGanttWeeks(weeks);
        setRowVisible(visible);
        setGanttTasks(tasks);
    }
    /* ==============================
          draw chart
      ============================== */
    function drawChart(tasks) {
        const canvasEl = d3.select(canvasRef.current);
        canvasEl.selectAll('svg').remove();
        const taskArr = tasks.reduce((acc, item) => {
            let res = acc.concat([item]);
            if (item.subTasks)
                res = [...res, ...item.subTasks];
            return res;
        }, []);
        const firstDate = new Date(ganttDates[0].fullDate);
        const taskRows = canvasEl.selectAll('.task_row').data(taskArr);
        taskRows
            .append('div')
            // .attr('class', 'task')
            .attr('class', function (data) {
            return data.isMain ? 'task task--main' : 'task';
        })
            .filter(function (data) {
            return data.start && data.end;
        })
            .style('top', function (data) {
            return (constants_1.CELL_STYLE.height - constants_1.ROW_STYLE.sub.height) / 2 + 'px';
        })
            .style('left', function (data) {
            return (0, date_1.getDateLength)(firstDate, data.start) * constants_1.CELL_STYLE.width + 1 + 'px';
        })
            .style('height', function (data) {
            return constants_1.ROW_STYLE[data.isMain ? 'main' : 'sub'].height + 'px';
        })
            .style('width', function (data) {
            return ((0, date_1.getDateLength)(data.start, data.end) + 1) * constants_1.CELL_STYLE.width - 2 + 'px';
        })
            .style('background', function (data) {
            return data.rate <= 0
                ? data.isMain
                    ? constants_1.STEP_COLORS.onProgressLeft
                    : constants_1.STEP_COLORS.beforeProgress
                : data.rate >= 100
                    ? data.isMain
                        ? constants_1.STEP_COLORS.finished
                        : constants_1.STEP_COLORS.finishedDark
                    : data.isMain
                        ? constants_1.STEP_COLORS.finished //STEP_COLORS.onProgressLeft //: 투명도로 진척도 나타낼 때
                        : constants_1.STEP_COLORS.onProgress;
        })
            .style('border-radius', function (data) {
            return Number(constants_1.ROW_STYLE[data.isMain ? 'main' : 'sub'].height / 2) + 'px';
        })
            .style('cursor', 'pointer')
            .on('mousemove mouseover', function (event, data) {
            setTooltipProps({
                top: event.clientY,
                left: event.clientX,
                title: data.title,
                dates: `${(0, date_2.stringDateFormDate)(data.start)} ~ ${(0, date_2.stringDateFormDate)(data.end)}`,
                progress: `진행율: ${data.rate}%`,
            });
        })
            .on('mouseout', function () {
            setTooltipProps(null);
        })
            .filter(function (data) {
            return data.isMain;
        })
            .append('div')
            .style('position', 'relative')
            .style('width', function (data) {
            const onProgressed = Math.ceil((0, date_1.getDateLength)(data.start, data.end) * data.rate * 0.01);
            return onProgressed * constants_1.CELL_STYLE.width + 'px';
        })
            .style('height', function (data) {
            return constants_1.ROW_STYLE[data.isMain ? 'main' : 'sub'].height + 'px';
        })
            .style('border-radius', function (data) {
            return Number(constants_1.ROW_STYLE[data.isMain ? 'main' : 'sub'].height / 2) + 'px';
        })
            // .style('background', STEP_COLORS.finished) //모든 항목에 동일한 컬러 적용할 때
            .style('background', function (data) {
            return data.isMain ? constants_1.STEP_COLORS.finished : constants_1.STEP_COLORS.onProgress;
        })
            // .filter(function(data) {
            //     return data;
            // })
            .append('span')
            .attr('class', 't-rate')
            .text(function (data) {
            return data.rate + '%';
        })
            .style('position', 'absolute')
            .style('top', '-5px')
            .style('left', function (data) {
            return data.len * constants_1.CELL_STYLE.width + 40 + 'px';
        });
    }
    /* ==============================
          chart click
      ============================== */
    function handleSideClick(rowNum) {
        const newValue = !rowVisible[rowNum];
        setRowVisible((rowVisible) => rowVisible.map((item, idx) => (idx === rowNum ? newValue : item)));
    }
    function handleTitleClick(rowClass) {
        if (!bodyRef.current) {
            return;
        }
        const row = bodyRef.current.getElementsByClassName(`${ROW_CLASS}_${rowClass}`)[0];
        const target = row.querySelector('.task');
        if (target) {
            scrollToFocus(bodyRef.current, target);
        }
    }
    /* ==============================
          focus row
      ============================== */
    function scrollToFocus(bodyEl, targetEl) {
        const targetX = targetEl.getBoundingClientRect().x - bodyEl.getBoundingClientRect().x + bodyEl.scrollLeft - constants_1.CELL_STYLE.width;
        bodyEl.scrollTo({
            left: targetX,
            top: 0,
            behavior: 'smooth',
        });
    }
    (0, react_1.useEffect)(() => {
        if (ganttTasks.length > 0 && ganttDates) {
            drawChart(ganttTasks);
        }
    }, [ganttDates, ganttTasks]);
    (0, react_1.useEffect)(() => {
        if (bodyRef.current && todayRef.current && !todayFocus) {
            scrollToFocus(bodyRef.current, todayRef.current);
            setTodayFocus(true);
        }
    }, [bodyRef.current, todayRef.current, todayFocus]);
    (0, react_1.useEffect)(() => {
        if (data)
            setGanttData(data);
    }, [data]);
    return ((0, jsx_runtime_1.jsxs)(D.FlexCols, { _gap: 24, children: [(0, jsx_runtime_1.jsxs)(D.FlexRows, { _content: "space-between", children: [(0, jsx_runtime_1.jsxs)(D.FlexRows, { as: "ul", _width: "auto", _gap: 24, _media: "mobile", _m_gap: 12, _wrap: "wrap", children: [(0, jsx_runtime_1.jsx)(S.GanttCaption, { color: constants_1.STEP_COLORS.finishedDark, children: "\uC644\uB8CC" }), (0, jsx_runtime_1.jsx)(S.GanttCaption, { color: constants_1.STEP_COLORS.onProgress, children: "\uC9C4\uD589\uC911" }), (0, jsx_runtime_1.jsx)(S.GanttCaption, { color: constants_1.STEP_COLORS.beforeProgress, children: "\uC9C4\uD589\uC804" }), (0, jsx_runtime_1.jsx)(S.GanttCaption, { color: constants_1.DATE_COLORS.today, children: "\uC624\uB298\uB0A0\uC9DC" })] }), modDate && (0, jsx_runtime_1.jsxs)(S.GanttModDate, { children: ["\uB9C8\uC9C0\uB9C9 \uC218\uC815\uC77C: ", modDate.substring(0, 10)] })] }), (0, jsx_runtime_1.jsxs)(S.GanttChart, { children: [(0, jsx_runtime_1.jsxs)(S.GanttChartAside, { children: [(0, jsx_runtime_1.jsx)(S.AsideTitleCell, { children: "\uC791\uC5C5 \uCE74\uD14C\uACE0\uB9AC / \uCD94\uAC00 \uCE74\uD14C\uACE0\uB9AC" }), ganttTasks.map((task, idx) => ((0, jsx_runtime_1.jsxs)(S.AsideCell, { children: [(0, jsx_runtime_1.jsxs)(S.CategoryCell, { main: true, visible: rowVisible[idx], hasSubTasks: task.hasOwnProperty('subTasks') && task.subTasks.length > 0, children: [(0, jsx_runtime_1.jsx)("span", { onClick: (e) => handleTitleClick(`${idx}`), children: task.title }), task.subTasks && task.subTasks.length > 0 && ((0, jsx_runtime_1.jsx)(S.CategoryDpBtn, { onClick: (e) => {
                                                    handleSideClick(idx);
                                                }, children: rowVisible[idx] ? '닫기' : '펼쳐 보기' }))] }, idx), task.subTasks &&
                                        task.subTasks.map((sub, sIdx) => ((0, jsx_runtime_1.jsx)(S.CategoryCell, { main: false, visible: rowVisible[idx], children: (0, jsx_runtime_1.jsx)("span", { onClick: (e) => handleTitleClick(`${idx}_${sIdx}`), children: sub.title }) }, sIdx)))] }, idx)))] }), (0, jsx_runtime_1.jsx)(S.GanttChartTable, { ref: bodyRef, children: (0, jsx_runtime_1.jsxs)(S.Table, { children: [(0, jsx_runtime_1.jsx)(S.TalbleHead, { children: ganttWeeks.map((week, wIdx) => ((0, jsx_runtime_1.jsxs)(S.HeadCell, { children: [(0, jsx_runtime_1.jsx)(S.WeekCell, { children: week.week }), (0, jsx_runtime_1.jsx)(S.DatesCell, { children: week.dates.map((date, dIdx) => ((0, jsx_runtime_1.jsx)(S.DateCell, { id: date.id, today: date.fullDate === date_1.TODAY_STRING, weekend: date.weekend, ref: date.fullDate === date_1.TODAY_STRING ? todayRef : null, children: date.date }, dIdx))) })] }, wIdx))) }), (0, jsx_runtime_1.jsxs)(S.TalbleBody, { ref: canvasRef, children: [ganttTasks.map((task, tIdx) => ((0, jsx_runtime_1.jsxs)(react_1.default.Fragment, { children: [(0, jsx_runtime_1.jsx)(S.DatesCell, { className: `${ROW_CLASS} ${ROW_CLASS}_${tIdx}`, children: ganttDates.map((date, dIdx) => ((0, jsx_runtime_1.jsx)(S.DateCell, { today: date.fullDate === date_1.TODAY_STRING, weekend: date.weekend, empty: true }, dIdx))) }), task.subTasks &&
                                                    task.subTasks.map((sub, sIdx) => ((0, jsx_runtime_1.jsx)(S.DatesCell, { className: `${ROW_CLASS} ${ROW_CLASS}_${tIdx}_${sIdx}`, visible: rowVisible[tIdx], children: ganttDates.map((date, dIdx) => ((0, jsx_runtime_1.jsx)(S.DateCell, { today: date.fullDate === date_1.TODAY_STRING, weekend: date.weekend, empty: true }, dIdx))) }, sIdx)))] }, tIdx))), tooltipProps !== null && ((0, jsx_runtime_1.jsxs)(S.ToolTip, { top: tooltipProps.top + 20, left: tooltipProps.left, children: [(0, jsx_runtime_1.jsx)("span", { className: "t-cate", children: tooltipProps.title }), (0, jsx_runtime_1.jsx)("span", { className: "t-dates", children: tooltipProps.dates }), (0, jsx_runtime_1.jsx)("span", { className: "t-prg", children: tooltipProps.progress })] }))] })] }) })] })] }));
}
exports.default = PmsGantt;
