"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUser = exports.userState = exports.getLoginUser = void 0;
const _1 = require(".");
const recoil_1 = require("recoil");
const date_1 = require("../utils/date");
function getLoginUser(data) {
    return data
        ? {
            type: data.userType === 1 ? 'client' : 'partner',
            id: data.userType === 1 ? data.clientId : data.partnerId,
            name: data.userName,
            email: data.userEmail,
            no: data.userNo,
            tel: data.userPhone,
            profileFile: data.userType === 1 ? data.clientProfile || '' : data.partnerProfile || '',
            sido: data.siDo || '',
            gugun: data.guGun || '',
            companyName: data.companyName || '',
            repName: data.representativeName || '',
            corpCategory: data.corporateCtg || '',
            marketing: data.agreementTerms4 === 1,
            isSnsUser: data.provider === '1',
            phoneCheck: JSON.parse(data.phoneCheck),
        }
        : null;
}
exports.getLoginUser = getLoginUser;
/* ==============================
    LOGIN USER STATE
============================== */
const STORAGE_USER_KEY = 'gigsUser';
const loginOnDay = 3;
const userLocalStorageEffect = () => ({ setSelf, onSet }) => {
    const savedData = localStorage.getItem(STORAGE_USER_KEY);
    if (!savedData) {
        setSelf(null);
    }
    else {
        const userData = JSON.parse(savedData);
        const isOverExpireDate = Math.ceil((new Date(userData.expireDate).getTime() - date_1.TODAY.getTime()) / (1000 * 60 * 60 * 24)) < 0;
        if (isOverExpireDate) {
            localStorage.removeItem(STORAGE_USER_KEY);
            setSelf(null);
        }
        else {
            const newUserData = {
                ...userData,
                expireDate: (0, date_1.getNdayAfter)(loginOnDay),
            };
            localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(newUserData));
            setSelf(newUserData);
        }
    }
    onSet((newValue) => {
        if (newValue === null) {
            localStorage.removeItem(STORAGE_USER_KEY);
        }
        else {
            const newUserData = {
                ...newValue,
                expireDate: (0, date_1.getNdayAfter)(loginOnDay),
            };
            localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(newUserData));
        }
    });
};
exports.userState = (0, recoil_1.atom)({
    key: _1.stateKeys['user'],
    default: null,
    effects: [userLocalStorageEffect()],
});
function useUser() {
    const [user, setUser] = (0, recoil_1.useRecoilState)(exports.userState);
    function login(data) {
        let loginUser = getLoginUser(data);
        if (!loginUser) {
            window.location.href = '/';
        }
        else {
            setUser(loginUser);
            // localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(loginUser));
            window.location.href = loginUser.isSnsUser ? '/' : `/#/mypage/${loginUser.type}`;
            // if(hasSiteHistory) {
            //     history.back();
            // } else {
            //     window.location.href= loginUser.isSnsUser?'/' : `/#/mypage/${loginUser.type}`;
            // }
        }
    }
    // 이동 경로 저장 / 회원가입 후 이동
    // function loginAfterJoin(data) {
    //     const loginUser = getLoginUser(data);
    //     if(!loginUser) {
    //         window.location.href = '/';
    //     } else {
    //         setUser(loginUser);
    //         localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(loginUser));
    //         if(hasSiteHistory) {
    //             history.back();
    //         } else {
    //             window.location.href= loginUser.isSnsUser?'/' : `/#/mypage/${loginUser.type}`;
    //         }
    //     }
    // };
    function modify(data) {
        setUser(data);
    }
    function logout() {
        localStorage.removeItem(STORAGE_USER_KEY);
        window.location.href = '/';
    }
    return {
        user: user,
        isClient: user && user.type === 'client',
        isPartner: user && user.type === 'partner',
        login: login,
        logout: logout,
        modify: modify,
    };
}
exports.useUser = useUser;
