"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PARTNER_SESSION_GUIDE = exports.CLIENT_SESSION_GUIDE = void 0;
exports.CLIENT_SESSION_GUIDE = [
    {
        session: '등록 검수',
        title: '관리자가 해당 프로젝트 등록 여부를 검수 중입니다.',
        descriptions: `- 프로젝트 등록 검수는 최대 24시간 소요되며, 주말 및 공휴일의 경우 일정이 지연될 수 있습니다.\n- 검수 완료 시 관리자가 직접 클라이언트에게 연락을 드리며, 해당 프로젝트는 ‘프로젝트 찾기’ 메뉴에 노출 및 모집이 시작됩니다.\n- 프로젝트 내용은 서비스 약관 기준을 기본으로 하며, 불필요한 광고 / 홍보 목적 및 불법적인 내용을 포함한 경우 프로젝트 검수에서 제외됩니다.`,
    },
    {
        session: '모집중',
        descriptions: `- 파트너에게 미팅을 요청한 목록입니다. \n- 미팅확정 혹은 조율이 필요할 경우, 긱플러스에서 개별적으로 연락을 드립니다.`,
        descriptions2: `- 현재 요청된 미팅이 없습니다. \n - 지원자 확인 후 미팅을 희망하시는 파트너에게 미팅을 신청해주세요. `,
    },
    {
        session: '미팅',
        descriptions: `- 미팅 요청 후 파트너사로부터 수락여부 및 미팅 확정, 일정 등 상세사항은 긱플러스 담당 매니저와 유선 연락 후 확정됩니다.\n- 미팅 요청 후 수락여부 및 미팅 확정은 최대 24시간 소요되며, 주말 및 공휴일의 경우 일정이 지연될 수 있습니다.\n- 미팅 확정 후 아래의 일정을 꼭 확인하시어, 미팅에 참여 부탁드립니다.\n- 미팅 확정 후 사전 연락없이 미팅 취소 시 추후 파트너 매칭에 불이익이 발생될 수 있습니다.`,
    },
    {
        session: '견적',
        descriptions: `- 미팅 진행 후 원하는 파트너의 견적을 요청해주세요.\n - 긱플러스 담당 매니저에게 연락 후 상세 견적 요청 및 관련 문의를 진행하실 수 있습니다.`,
    },
    {
        session: '계약',
        descriptions: `- 계약서 작성 요청 시 파트너사에 전달됩니다.\n- 파트너사의 계약서 작성 완료 시 긱플러스 담당 매니저가 별도 연락을 드립니다.\n- 계약서 작성 완료 시 하단 목록에서 계약서 다운로드가 가능합니다.`,
    },
    {
        session: '진행 중',
        descriptions: `- 해당 프로젝트 회차 별 대금 지급은 계약서를 토대로 \‘프로젝트 관리 > 대금 지급\'에서 지급 요청이 가능합니다.\n- 프로젝트 일정 관리를 통하여 파트너사와 일정 및 진행 현황, 요청/문의사항 등 프로젝트가 관리가 가능합니다.`,
    },
    {
        session: '완료',
        descriptions: '- 해당 프로젝트가 완료되었습니다.',
    },
];
exports.PARTNER_SESSION_GUIDE = [
    {
        session: '지원',
        title: '프로젝트 지원 요청이 완료되었습니다. ',
        descriptions: `- 지원서에 긱플러스 이용약관에 위배되거나 개인연락처가 등록되어 있을 경우 지원서가 승인되지 않을 수 있습니다.\n- 프로젝트 내용을 분석한 지원서 작성 및 포트폴리오를 꼼꼼히 작성해 주시면 미팅 선정률이 높아집니다.\n- 프로젝트 지원은 서비스 약관 기준을 기본으로 하며, 불필요한 광고 / 홍보 목적 및 불법적인 내용을 포함한 경우 프로젝트 검수에서 제외됩니다.\n- 미팅 대상자로 선정되면 스케줄 매니저가 미팅 조율을 위해 연락 드립니다.`,
    },
    {
        session: '제안 받음',
        descriptions: `- 클라이언트에게 미팅요청을 제안받은 목록입니다.\n- 프로젝트 내용을 충분히 검토 후, 실제 진행 가능한 프로젝트에 수락 후 지원서를 작성해 주세요.\n- 미팅을 원하는 경우, 긱플러스에서 개별적으로 연락을 드립니다.`,
    },
    {
        session: '미팅',
        descriptions: `- 미팅 수락 후 클라이언트로부터 수락여부 및 미팅 확정, 일정 등 상세사항은 긱플러스 담당 매니저와 유선 연락 후 확정됩니다.\n- 미팅 수락여부 및 미팅 확정은 최대 24시간 소요되며, 주말 및 공휴일의 경우 일정이 지연될 수 있습니다.\n- 미팅 확정 후 아래의 일정을 꼭 확인하시어, 미팅에 참여 부탁드립니다.\n- 미팅 확정 후 사전 연락없이 미팅 취소 시 추후 매칭에 불이익이 발생될 수 있습니다.`,
    },
    {
        session: '견적',
        descriptions: `- 계약서 작성 요청 시 클라이언트에 전달됩니다.\n- 클라이언트의 계약서 작성 완료 시 긱플러스 담당 매니저가 별도 연락을 드립니다.\n- 계약서 작성 완료 시 하단 목록에서 계약서 다운로드가 가능합니다.`,
    },
    {
        session: '계약',
        descriptions: `- 계약서 작성 요청 시 클라이언트에 전달됩니다.\n- 클라이언트의 계약서 작성 완료 시 긱플러스 담당 매니저가 별도 연락을 드립니다.\n- 계약서 작성 완료 시 하단 목록에서 계약서 다운로드가 가능합니다.`,
    },
    {
        session: '진행 중',
        descriptions: `- 해당 프로젝트 회차 별 대금 지급은 계약서를 토대로 ‘프로젝트 관리 > 대금 지급'에서 지급 요청이 가능합니다.\n- 프로젝트 일정 관리를 통하여 파트너사와 일정 및 진행 현황, 요청/문의사항 등 프로젝트가 관리가 가능합니다. `,
    },
    {
        session: '완료',
    },
];
