"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mySessionData = exports.mySessionState = exports.myMainPageState = void 0;
const recoil_1 = require("recoil");
const _1 = require(".");
exports.myMainPageState = (0, recoil_1.atom)({
    key: _1.stateKeys['myMainPage'],
    default: null,
});
exports.mySessionState = (0, recoil_1.atom)({
    key: _1.stateKeys['mySession'],
    default: null,
});
exports.mySessionData = (0, recoil_1.selectorFamily)({
    key: _1.stateKeys['mySession'] + '_DATA',
    get: (projectNo) => ({ get }) => {
        const data = get(exports.mySessionState);
        if (data && data.projectNo === projectNo)
            return data;
        else
            return null;
    },
    set: (projectNo) => ({ set, get }, newValue) => {
        set(exports.mySessionState, newValue);
    },
});
