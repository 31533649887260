"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const recoil_1 = require("recoil");
const Display_1 = require("../../../components/ui/Display");
const style_1 = require("../../../components/ui/Box/style");
const mypage_1 = require("../../../service/client/mypage");
const scrap_1 = require("../../../service/client/scrap");
const alert_1 = require("../../../state/alert");
const mypage_2 = require("../../../state/mypage");
const user_1 = require("../../../state/user");
const react_router_dom_1 = require("react-router-dom");
const navigation_1 = require("../../../schemes/navigation");
;
const SCRAP_ICON = (scrapped, styleTheme, size) => ((0, jsx_runtime_1.jsx)("svg", { width: size === "small" ? "18" : "20", height: size === "small" ? "18" : "18", viewBox: "0 0 22 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.9932 3.15567C8.9938 0.796526 5.65975 0.161923 3.15469 2.32219C0.649644 4.48246 0.296968 8.09433 2.2642 10.6493C3.89982 12.7736 8.84977 17.2538 10.4721 18.7039C10.6536 18.8661 10.7444 18.9473 10.8502 18.9791C10.9426 19.007 11.0437 19.007 11.1361 18.9791C11.2419 18.9473 11.3327 18.8661 11.5142 18.7039C13.1365 17.2538 18.0865 12.7736 19.7221 10.6493C21.6893 8.09433 21.3797 4.45974 18.8316 2.32219C16.2835 0.184647 12.9925 0.796526 10.9932 3.15567Z", stroke: scrapped ?
            '#E3335B'
            : (styleTheme === 'white' ?
                '#FFFFFF'
                : styleTheme === 'box' ?
                    '#424242'
                    : '#BDBDBD'), fill: scrapped ? '#E3335B' : 'none', strokeLinecap: "round", strokeWidth: styleTheme === 'white' ? 2 : 1, strokeLinejoin: "round" }) }));
/*
    - 스크랩
    - [사용] 프로젝트, 포트폴리오 스크랩
    - [적용] 프로젝트 항목, 포트폴리오 항목
*/
function UserScrapContainer({ type, typeNo, scrapped, styleTheme = 'normal', size = 'base', customStyle = {} }) {
    const { textAlert, confirm } = (0, alert_1.useAlert)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const [isScarpped, setIsScrapped] = (0, react_1.useState)(false);
    const { user, isClient, isPartner } = (0, user_1.useUser)();
    const [myMainPageData, setMyMainPageData] = (0, recoil_1.useRecoilState)(mypage_2.myMainPageState);
    async function resetMyPagePortfolio() {
        const response = isClient ?
            await (0, mypage_1.getClientScrap)(user.no, 1)
            : await (0, mypage_1.getPartnerScrap)(user.no, 1);
        const { arrayData } = response.data;
        setMyMainPageData({
            status: myMainPageData?.status || [],
            projects: myMainPageData?.projects || [],
            scraps: arrayData || []
        });
    }
    ;
    async function handleScrapProject(userNo, projectNo) {
        const response = await (0, scrap_1.scrapProject)(userNo, projectNo);
        const { code } = response.data;
        if (code === 200)
            setIsScrapped(true);
        if (code === 201)
            setIsScrapped(false);
        resetMyPagePortfolio();
    }
    ;
    async function handleScrapPortfolio(userNo, portfolioNo) {
        const response = await (0, scrap_1.scrapPortfolio)(userNo, portfolioNo);
        const { code } = response.data;
        if (code === 200)
            setIsScrapped(true);
        if (code === 201)
            setIsScrapped(false);
        resetMyPagePortfolio();
    }
    ;
    function handleScrap(e) {
        e.preventDefault();
        if (!user) {
            confirm({
                text: `로그인 후 이용이 가능합니다. \n 로그인 하시겠습니까?`,
                onConfirm: () => { navigate(navigation_1.SITE_NAVIGATION.login); },
                onCancel: (() => { })
            });
            return;
        }
        if (type === 'portfolio') {
            if (isPartner)
                textAlert('클라이언트로 로그인 후 이용해 주세요.');
            else
                handleScrapPortfolio(user.no, typeNo);
        }
        if (type === 'project') {
            if (isClient)
                textAlert('파트너로 로그인 후 이용해 주세요.');
            else
                handleScrapProject(user.no, typeNo);
        }
    }
    ;
    (0, react_1.useEffect)(() => {
        setIsScrapped(scrapped);
    }, [scrapped]);
    return ((0, jsx_runtime_1.jsx)(style_1.Box, { _width: styleTheme === 'box' ? '48px' : '20px', _height: styleTheme === 'box' ? '48px' : '20px', _radius: styleTheme === 'box' ? '4px' : '0', _border: styleTheme === 'box' ? '1px' : '0', _bdcolor: "gray2", _bgcolor: "transparent", _margin: "0", _padding: "0", style: customStyle, children: (0, jsx_runtime_1.jsx)(Display_1.FlexRows, { _content: "center", _items: "center", _height: "100%", children: (0, jsx_runtime_1.jsx)(Display_1.FlexRows, { as: "button", _content: "center", _items: "center", onClick: handleScrap, children: SCRAP_ICON(isScarpped, styleTheme, size) }) }) }));
}
exports.default = UserScrapContainer;
