"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TODAY_STRING = exports.TODAY = exports.KO_DAY = exports.getNdayAfter = exports.getDateLength = exports.getDateDiff = exports.getLastDate = exports.getNextSunDay = exports.getPrevMonDay = exports.getTomorrow = exports.getDashedDate = exports.getStrDate = exports.getStrMonth = exports.getStrYear = exports.getValidDate = exports.stringDateFormDate = void 0;
// getstring
function stringDateFormDate(dates) {
    const year = dates.getFullYear();
    const month = dates.getMonth() + 1;
    const date = dates.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}`;
}
exports.stringDateFormDate = stringDateFormDate;
function getValidDate(dates) {
    const values = {
        year: 0,
        month: 0,
        date: 0,
    };
    for (const d in dates) {
        const numValue = Number(dates[d]);
        if (isNaN(numValue) || numValue <= 0)
            return null;
        if (d === 'year') {
            values[d] = Number(numValue >= 2000 ? dates[d] : `20${numValue < 10 ? `0${numValue}` : dates[d]}`);
        }
        else {
            values[d] = numValue;
        }
    }
    const validDate = new Date(values.year, values.month - 1, values.date);
    return validDate;
}
exports.getValidDate = getValidDate;
function getStrYear(value, stringCnt = 4) {
    if (!value)
        return '';
    let year = value.getFullYear().toString();
    return year.substring(year.length - stringCnt, year.length);
}
exports.getStrYear = getStrYear;
function getStrMonth(value) {
    if (!value)
        return '';
    let monthIdx = value.getMonth() + 1;
    return `${monthIdx < 10 ? '0' : ''}` + monthIdx;
}
exports.getStrMonth = getStrMonth;
function getStrDate(value) {
    if (!value)
        return '';
    let dateIdx = value.getDate();
    return `${dateIdx < 10 ? '0' : ''}` + dateIdx;
}
exports.getStrDate = getStrDate;
function getDashedDate(value) {
    if (!value)
        return '';
    return `${getStrYear(value)}-${getStrMonth(value)}-${getStrDate(value)}`;
}
exports.getDashedDate = getDashedDate;
function getTomorrow() {
    const tomorrow = new Date(new Date());
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
}
exports.getTomorrow = getTomorrow;
// 전주 월요일 반환
function getPrevMonDay(date) {
    const day = date.getDay();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + (-day - 6));
}
exports.getPrevMonDay = getPrevMonDay;
// 차주 일요일 반환
function getNextSunDay(date) {
    const day = date.getDay();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + (7 - day));
}
exports.getNextSunDay = getNextSunDay;
// 달의 마지막 날 반환
function getLastDate(year, month) {
    const lastDate = new Date(year, month + 1, 0);
    return lastDate.getDate();
}
exports.getLastDate = getLastDate;
function getDateDiff(start, end) {
    const diffDate = start.getTime() - end.getTime();
    return Math.ceil(diffDate / (1000 * 60 * 60 * 24));
}
exports.getDateDiff = getDateDiff;
// 두 날 차이 반환
function getDateLength(start, end) {
    return Math.abs(getDateDiff(start, end));
}
exports.getDateLength = getDateLength;
// N일 후 반환
function getNdayAfter(nDay) {
    const nDayAfter = new Date();
    nDayAfter.setDate(nDayAfter.getDate() + nDay);
    return nDayAfter;
}
exports.getNdayAfter = getNdayAfter;
// today
exports.KO_DAY = ['일', '월', '화', '수', '목', '금', '토'];
exports.TODAY = new Date();
exports.TODAY_STRING = `${exports.TODAY.getFullYear()}-${exports.TODAY.getMonth() + 1}-${exports.TODAY.getDate()}`;
