"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Main = exports.Aside = exports.Page = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const utils_1 = require("../../../themes/utils");
/*
    - ASIDE-LAYOUT
    - [사용] aside|main으로 분리 된 레이아웃에 사용되는 컴포넌트
    - [적용] 프로젝트|포트폴리오 상세페이지, 마이페이지 등
*/
exports.Page = styled_components_1.default.main `
  z-index: 21;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 60px calc((100vw - 1080px) / 2) 160px;
  gap: ${(props) => (props._gap ? (0, utils_1.cssValue)(props._gap) : '30px')};
  flex-direction: ${(props) => (props._direction ? props._direction : 'row')};
  background: ${(props) => props.styleTheme === 'background'
    ? props.theme.colors.gray1
    : props.styleTheme === 'dimBackground'
        ? 'linear-gradient(180deg, rgba(0,0,0,.05), transparent)'
        : 'white'};

  @media (max-width: 1080px) {
    padding: 60px 15px 160px;
  }

  @media (${(props) => props.theme.media.tablet}) {
    flex-direction: ${(props) => (props._direction ? props._direction : 'column')};
    gap: ${(props) => (props._gap ? props._gap : '12px')};
    padding: 0 0 ${(props) => (props._m_bottom ? '160px' : '80px')};

    > aside {
      width: 100% !important;

      > * {
        position: relative;
      }
    }

    > section {
      width: 100% !important;
    }
  }
`;
exports.Aside = styled_components_1.default.aside `
  z-index: 23;
  height: auto;
  width: ${(props) => (props._width ? (0, utils_1.cssValue)(props._width) : '330px')};

  > * {
    position: sticky;
    top: ${(props) => (props._top ? props._top : 100)}px;
    left: 0;
  }

  @media (${(props) => props.theme.media.tablet}) {
    display: ${(props) => (props._m_display ? 'block' : 'none')};
    width: 100% !important;

    > * {
      position: relative;
      top: 0;
    }

    ${(props) => props.styleTheme === 'mobileFix' &&
    (0, styled_components_1.css) `
        z-index: 23;
        display: block !important;
        position: fixed;
        top: 56px;
        left: 0;
        width: 100%;

        & + section {
          z-index: 22;
          position: relative;
          padding-top: 56px;
        }
      `}
  }
`;
exports.Main = styled_components_1.default.section `
  z-index: 24;
  position: relative;
  width: ${(props) => (props._width ? (0, utils_1.cssValue)(props._width) : 'calc(100% - 390px)')};

  @media (${(props) => props.theme.media.tablet}) {
    width: 100% !important;
  }
`;
