"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const style_1 = require("../../ui/Box/style");
const Icons_1 = require("../../ui/Loading/Icons");
const style_2 = require("./style");
const D = __importStar(require("../../ui/Display"));
function CardList({ listType = 'project', children = (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}), customStyle = {}, pageControl = false, currentPage = 1, lastPage = 1, loading = false, handleNextPage = () => { }, }) {
    const tempDivRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        let io;
        if (pageControl && tempDivRef.current) {
            io = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    if (currentPage < lastPage && !loading) {
                        handleNextPage(currentPage + 1);
                    }
                    io.unobserve(tempDivRef.current);
                }
            }, { threshold: 1.0 });
            if (tempDivRef.current)
                io.observe(tempDivRef.current);
        }
        return () => {
            if (io && tempDivRef.current) {
                io.unobserve(tempDivRef.current);
            }
        };
    }, [pageControl, currentPage, loading]);
    return ((0, jsx_runtime_1.jsx)(D.FlexCols, { children: (0, jsx_runtime_1.jsxs)(style_2.CardList, { type: listType, style: customStyle, children: [children, pageControl && currentPage < lastPage && ((0, jsx_runtime_1.jsx)(style_1.Box, { _width: "100%", _height: "40px", _bgcolor: "white", ref: tempDivRef, className: "loadingBox", children: (0, jsx_runtime_1.jsx)(Icons_1.LoadingColorDots, {}) }))] }) }));
}
exports.default = CardList;
