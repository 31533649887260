"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.copyUrl = void 0;
const copyUrl = (url) => {
    const temp = document.createElement('textarea');
    temp.style.width = '1px';
    temp.style.height = '1px';
    document.body.appendChild(temp);
    temp.value = url;
    temp.select();
    document.execCommand('copy');
    document.body.removeChild(temp);
};
exports.copyUrl = copyUrl;
