"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInfiniteAliemListQuery = exports.useAliemCheckQuery = void 0;
const react_query_1 = require("@tanstack/react-query");
const aliem_1 = require("../../service/client/aliem");
const aliemKeys = {
    check: ['aliem'],
    list: ['aliem_list'],
};
const useAliemCheckQuery = (userNo) => (0, react_query_1.useQuery)({
    queryKey: aliemKeys.check,
    queryFn: async () => await (0, aliem_1.getAliemState)(userNo),
    select: (data) => data.data.code === 201,
});
exports.useAliemCheckQuery = useAliemCheckQuery;
const useInfiniteAliemListQuery = (userNo) => (0, react_query_1.useInfiniteQuery)({
    queryKey: aliemKeys.list,
    queryFn: async ({ pageParam = 0 }) => await (0, aliem_1.getAliemList)(userNo, pageParam),
    getNextPageParam: (lastPage, pages) => {
        return lastPage.data.pageCode + 1 > pages.length;
    },
});
exports.useInfiniteAliemListQuery = useInfiniteAliemListQuery;
