"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAliemList = exports.getAliemState = void 0;
const _1 = require(".");
const url = '/api/sendAliem';
// 알림 상태값 요청
function getAliemState(userNo) {
    return _1.client.post(url, {
        userNo: userNo,
        aliemStatus: false,
        page: 1,
    });
}
exports.getAliemState = getAliemState;
// 알림 목록 요청
function getAliemList(userNo, page) {
    return _1.client.post(url, {
        userNo: userNo,
        aliemStatus: true,
        page: page,
    });
}
exports.getAliemList = getAliemList;
