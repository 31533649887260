"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProjectPostData = void 0;
const number_1 = require("../utils/number");
const text_1 = require("../utils/text");
function getProjectPostData(data) {
    if (!data)
        return null;
    const { authCheck, projectNo, projectName, clientProfile, statusValueArr, projectIconArr, recrStartDate, recrEndDate, projectClass, siDoArea, guGunArea, estimatedPeriod, minPrice, maxPrice, projectProcess, projectSituation, projectPlan, wantTechnologyPlan, wantTechnologyDesign, wantTechnologyDev, wantDevelop, wantFramework, meeting, paymentMethod, projectContent, projectFiles, zzimNum, clientId, siDo, guGun, authcheck, wantTechnologyCtg, contractProject, completeProject, userNo, } = data;
    return {
        authCheck: authCheck == 3 || authCheck === 5,
        projectNo: projectNo || -1,
        projectName: (0, text_1.dashString)(projectName),
        clientProfile: clientProfile ? clientProfile.s3Link + clientProfile.s3FileName : '',
        statusValueArr: statusValueArr || [],
        projectIconArr: projectIconArr || [],
        recrDate: recrStartDate && recrEndDate
            ? `${(0, text_1.dashString)(recrStartDate.substring(0, 10))} ~ ${(0, text_1.dashString)(recrEndDate.substring(0, 10))}`
            : '-',
        projectClass: (0, text_1.dashString)(projectClass),
        estimatedPeriod: (0, text_1.dashString)(estimatedPeriod),
        price: `${(0, number_1.koreanWon)(minPrice)} ${maxPrice > 0 ? ` ~ ${(0, number_1.koreanWon)(maxPrice)}` : '이상'}`,
        projectProcess: (0, text_1.dashString)(projectProcess),
        projectSituation: (0, text_1.dashString)(projectSituation),
        projectPlan: (0, text_1.dashString)(projectPlan),
        wantTechnologyPlan: wantTechnologyPlan.replaceAll(',', ', '),
        wantTechnologyDesign: wantTechnologyDesign.replaceAll(',', ', '),
        wantTechnologyDev: wantTechnologyDev.replaceAll(',', ', '),
        wantDevelop: (0, text_1.dashString)(wantDevelop),
        wantFramework: (0, text_1.dashString)(wantFramework),
        meeting: (0, text_1.dashString)(meeting),
        paymentMethod: (0, text_1.dashString)(paymentMethod),
        projectContent: (0, text_1.dashString)(projectContent),
        projectFiles: projectFiles || [],
        zzimNum: zzimNum || 0,
        clientId: (0, text_1.dashString)(clientId),
        siDo: (0, text_1.dashString)(siDo),
        siDoArea: (0, text_1.dashString)(siDoArea),
        guGun: (0, text_1.dashString)(guGun),
        guGunArea: (0, text_1.dashString)(guGunArea),
        authcheck: authcheck || false,
        wantTechnologyCtg: (0, text_1.dashString)(wantTechnologyCtg),
        contractProject: contractProject || 0,
        completeProject: completeProject || 0,
        userNo: userNo || -1,
        applyAble: statusValueArr.indexOf('완료') < 0 && statusValueArr.indexOf('모집 마감') < 0,
    };
}
exports.getProjectPostData = getProjectPostData;
