"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACTIVE_CLASS = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const S = __importStar(require("./style"));
const App_1 = require("../../../App");
const recoil_1 = require("recoil");
const ui_1 = require("../../../state/ui");
/*
    - FLOATING-CATEGORY
    - [사용] 스크롤 시 카테고리 네비게이션이 뷰포트 상단에 fixed 되어야 하는 페이지
    - [적용] 클라이언트|파트너 이용가이드
*/
exports.ACTIVE_CLASS = 'fc-active';
function FloatingCategoryLayout({ categories, sections }) {
    const isTablet = (0, react_1.useContext)(App_1.ScreenContext).tablet;
    const navRef = (0, react_1.useRef)(null);
    const contentsRef = (0, react_1.useRef)(null);
    const [navTop, setNavTop] = (0, react_1.useState)(260);
    const [headerEl, setHeaderEl] = (0, react_1.useState)(null);
    const [sectionEls, setSectionEls] = (0, react_1.useState)(null);
    const [floatingActive, setFloatingActive] = (0, react_1.useState)(false);
    const [activeCategory, setActiveCategory] = (0, react_1.useState)({
        direction: 'next',
        index: 0,
    });
    const PcHeaderUi = (0, recoil_1.useRecoilValue)(ui_1.PcHeaderUiState);
    const setPcHeaderUi = (0, recoil_1.useSetRecoilState)(ui_1.PcHeaderUiState);
    const resetPcHeaderUi = (0, recoil_1.useResetRecoilState)(ui_1.PcHeaderUiState);
    function handleNavClick(index) {
        if (sectionEls) {
            const section = sectionEls[index];
            window.scrollTo({
                top: section.offsetTop - ((!floatingActive && headerEl ? headerEl.offsetHeight * 1.5 : 0) + (isTablet ? 60 : 0)),
                left: 0,
                behavior: 'smooth',
            });
        }
    }
    const handleNavFloating = (0, react_1.useCallback)((e) => {
        if (!isTablet) {
            const windowY = window.scrollY;
            const active = windowY >= navTop;
            if ((active && !floatingActive) || (!active && !floatingActive)) {
                setFloatingActive(active);
                setPcHeaderUi({ hidden: active });
            }
        }
    }, []);
    function setNavFocusInMobile() {
        const target = navRef.current.querySelectorAll('li')[activeCategory.index];
        target.scrollIntoView({
            block: 'center',
            inline: 'center',
        });
    }
    function handleSectionObserve(sections) {
        let prevCategory = activeCategory.index;
        sections.forEach((item, idx) => {
            const section = item;
            const io = new IntersectionObserver(function (entries) {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setActiveCategory({
                        direction: idx >= prevCategory ? 'next' : 'prev',
                        index: idx,
                    });
                    prevCategory = idx;
                }
            }, { threshold: 0.3 });
            io.observe(section);
        });
    }
    (0, react_1.useEffect)(() => {
        if (isTablet) {
            setNavFocusInMobile();
        }
    }, [activeCategory, isTablet]);
    (0, react_1.useEffect)(() => {
        if (navRef.current && !isTablet) {
            if (!isTablet) {
                window.scrollTo(0, 0);
                setFloatingActive(false);
            }
            setHeaderEl(document.querySelector('header'));
            window.addEventListener('scroll', handleNavFloating);
        }
    }, [navRef.current, isTablet]);
    (0, react_1.useEffect)(() => {
        if (contentsRef.current) {
            const contents = contentsRef.current.childNodes;
            setSectionEls(contents);
            handleSectionObserve(contents);
        }
    }, [contentsRef.current]);
    (0, react_1.useEffect)(() => {
        return () => {
            resetPcHeaderUi();
            window.removeEventListener('scroll', handleNavFloating);
        };
    }, []);
    return ((0, jsx_runtime_1.jsxs)(S.LayoutWrap, { children: [(0, jsx_runtime_1.jsx)(S.FloatingNav, { floating: isTablet ? true : floatingActive, active: activeCategory.index, navLength: categories.length, direction: activeCategory.direction, ref: navRef, children: (0, jsx_runtime_1.jsx)("ul", { children: categories.map((item, idx) => ((0, jsx_runtime_1.jsx)("li", { onClick: () => {
                            handleNavClick(idx);
                        }, children: (0, jsx_runtime_1.jsx)("span", { children: item }) }, idx))) }) }), (0, jsx_runtime_1.jsx)(S.SectionWrap, { ref: contentsRef, children: sections.map((item, idx) => ((0, jsx_runtime_1.jsx)("div", { children: item }, idx))) })] }));
}
exports.default = FloatingCategoryLayout;
