"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PATTERN_ERROR = exports.OBJECT_ERROR = exports.ARRAY_ERROR = exports.NUMBER_ERROR = exports.PWD_CHECK_PATTERN = exports.PWD_PATTERN = exports.EMAIL_PATTERN = exports.AUTH_PATTERN = exports.TEL_PATTERN = exports.ID_PATTERN = void 0;
exports.ID_PATTERN = {
    pattern: /^[0-9a-zA-Z]{5,20}$/,
    patternMessage: '아이디는 5~20자의 영문, 숫자 조합으로 사용 가능 합니다.',
    requiredMessage: '아이디는 필수 입력값 입니다.',
};
exports.TEL_PATTERN = {
    pattern: /^[0-9]{3}[0-9]{4}[0-9]{4}$/,
    patternMessage: '휴대전화번호는 -를 제외한 숫자를 입력해 주세요.',
    requiredMessage: '휴대전화 번호를 입력해 주세요.',
};
exports.AUTH_PATTERN = {
    pattern: /^[0-9]{6}$/,
    patternMessage: '인증번호 6자리 숫자를 입력해 주세요.',
    requiredMessage: '인증번호를 입력해 주세요.',
};
exports.EMAIL_PATTERN = {
    pattern: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
    patternMessage: '이메일 주소를 정확히 입력해 주세요.',
    requiredMessage: '이메일 주소는 필수 입력값 입니다.',
};
exports.PWD_PATTERN = {
    pattern: /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,12}$/,
    patternMessage: '비밀번호는 8자 이상 12자 이하의 문자, 숫자, 특수문자 조합으로 입력해 주세요.',
    requiredMessage: '비밀번호는 필수 입력값 입니다.',
};
const PWD_CHECK_PATTERN = (pwd) => ({
    pattern: new RegExp(pwd),
    patternMessage: pwd.length > 0 ? '비밀번호를 확인해 주세요.' : '',
    requiredMessage: pwd.length > 0 ? '비밀번호를 확인해 주세요.' : '',
});
exports.PWD_CHECK_PATTERN = PWD_CHECK_PATTERN;
const NUMBER_ERROR = (value, { required = false, requiredMessage = '값을 입력해 주세요.', patternMessage = '숫자 형식으로 입력해 주세요.', }) => {
    if (typeof value === 'number')
        value = value + '';
    value = value.replaceAll(',', '');
    if (required && value.length <= 0)
        return requiredMessage;
    if (isNaN(Number(value)))
        return patternMessage;
    return '';
};
exports.NUMBER_ERROR = NUMBER_ERROR;
const ARRAY_ERROR = (value, { requiredMessage = '필수 입력 사항입니다.' }) => {
    if (!value || value.length <= 0)
        return requiredMessage;
    else
        return '';
};
exports.ARRAY_ERROR = ARRAY_ERROR;
const OBJECT_ERROR = (value, { requiredMessage = '필수 입력 사항입니다.' }) => {
    if (Object.keys(value).length <= 0)
        return requiredMessage;
    else
        '';
};
exports.OBJECT_ERROR = OBJECT_ERROR;
const PATTERN_ERROR = (value, { required = false, requiredMessage = '필수 입력 사항입니다.', pattern = new RegExp(''), patternMessage = '값을 정확히 입력해 주세요.', }) => {
    if (!required && value.length <= 0)
        return '';
    if (required && value.length <= 0)
        return requiredMessage;
    if (!pattern.test(value))
        return patternMessage;
    return '';
};
exports.PATTERN_ERROR = PATTERN_ERROR;
