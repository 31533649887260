"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NEWPROJECT_RESIDENT_NAVIGATION = exports.NEWPROJECT_UNDERTAKE_NAVIGATION = exports.PARTNER_SESSION_NAVIGATIONS = exports.CLIENT_SESSION_NAVIGATIONS = exports.NEWPROJECT_NAVIGATION = exports.MYPROJECT_NAVIGATION = exports.PARTNER_MYPROJECT_NAVIGATION = exports.CLIENT_MYPROJECT_NAVIGATION = exports.PARTNER_MYPAGE_NAVIGATION = exports.CLIENT_MYPAGE_NAVIGATION = exports.MYINFORMATION_NAVIGATION = exports.TERMS_NAVIGATION = exports.MORE_NAVIGATION = exports.CUSTOMER_NAVIGATION = exports.GUIDE_NAVIGATION2 = exports.GUIDE_NAVIGATION = exports.REGISTER_NAVIGATION = exports.LIST_NAVIGATION = exports.PARAMS_NAVIGATION = exports.SITE_NAVIGATION = void 0;
exports.SITE_NAVIGATION = {
    main: '/',
    joinMenu: '/joinmenu',
    join: '/join',
    login: '/login',
    findId: '/findid',
    findPwd: '/findpwd',
    certification: '/mypage/account/certification',
    accountInfo: '/mypage/account/user',
    projects: '/projects',
    portfolios: '/portfolios',
    newProjet: '/project/new',
    newPortfolio: '/portfolio/new',
    guide: {
        client: '/service/guide/client',
        partner: '/service/guide/partner',
        escrow: '/service/guide/escrow',
        fee: '/service/guide/fee',
        pms: '/service/guide/pms',
        adjust: '/service/guide/adjust',
    },
    service: {
        notice: '/service/notice',
        useTerm: '/service/terms/use',
        privacyTerm: '/service/terms/privacy',
    },
    mypage: {
        client: {
            main: '/mypage/client',
            projects: '/mypage/client/projects',
            scraps: '/mypage/client/scraps',
        },
        partner: {
            main: '/mypage/partner',
            portfolios: '/mypage/partner/portfolios',
            projects: '/mypage/partner/projects',
            scraps: '/mypage/partner/scraps',
        },
    },
};
exports.PARAMS_NAVIGATION = {
    mypage: (userType) => {
        if (userType === 'client')
            return exports.SITE_NAVIGATION.mypage.client.main;
        if (userType === 'partner')
            return exports.SITE_NAVIGATION.mypage.partner.main;
        else
            return exports.SITE_NAVIGATION.main;
    },
    project: (no) => `/project/post/${no}`,
    portfolio: (no) => `/portfolio/post/${no}`,
};
exports.LIST_NAVIGATION = {
    project: { name: '프로젝트', path: exports.SITE_NAVIGATION.projects },
    portfolio: { name: '포트폴리오', path: exports.SITE_NAVIGATION.portfolios },
};
exports.REGISTER_NAVIGATION = {
    client: { name: '프로젝트 등록', path: exports.SITE_NAVIGATION.newProjet },
    partner: { name: '포트폴리오 등록', path: exports.SITE_NAVIGATION.newPortfolio },
};
exports.GUIDE_NAVIGATION = [
    { name: '클라이언트 가이드', path: exports.SITE_NAVIGATION.guide.client },
    { name: '파트너스 가이드', path: exports.SITE_NAVIGATION.guide.partner },
    { name: '에스크로란?', path: exports.SITE_NAVIGATION.guide.escrow },
    { name: '이용요금', path: exports.SITE_NAVIGATION.guide.fee },
    { name: 'i-PMS란?', path: exports.SITE_NAVIGATION.guide.pms },
    { name: '분쟁 가이드', path: exports.SITE_NAVIGATION.guide.adjust },
];
exports.GUIDE_NAVIGATION2 = exports.GUIDE_NAVIGATION.map((item) => {
    if (item.path === exports.SITE_NAVIGATION.guide.pms) {
        return { ...item, name: 'i-P.M.S란?' };
    }
    return item;
});
exports.CUSTOMER_NAVIGATION = [{ name: '공지사항', path: '/service/notice' }];
exports.MORE_NAVIGATION = [
    {
        name: '서비스 가이드',
        depth: exports.GUIDE_NAVIGATION,
    },
    {
        name: '고객센터',
        depth: exports.CUSTOMER_NAVIGATION,
    },
];
exports.TERMS_NAVIGATION = [
    { name: '이용약관', path: exports.SITE_NAVIGATION.service.useTerm },
    { name: '개인정보처리방침', path: exports.SITE_NAVIGATION.service.privacyTerm },
];
exports.MYINFORMATION_NAVIGATION = [
    { name: '긱플러스 인증', path: exports.SITE_NAVIGATION.certification },
    { name: '회원 정보 변경', path: exports.SITE_NAVIGATION.accountInfo },
];
exports.CLIENT_MYPAGE_NAVIGATION = [
    { name: '홈', path: exports.SITE_NAVIGATION.mypage.client.main },
    { name: '전체 프로젝트', path: exports.SITE_NAVIGATION.mypage.client.projects },
    { name: '관심 포트폴리오', path: exports.SITE_NAVIGATION.mypage.client.scraps },
    { name: '계정 설정', path: exports.SITE_NAVIGATION.certification },
];
exports.PARTNER_MYPAGE_NAVIGATION = [
    { name: '홈', path: exports.SITE_NAVIGATION.mypage.partner.main },
    { name: '포트폴리오', path: exports.SITE_NAVIGATION.mypage.partner.portfolios },
    { name: '전체 프로젝트', path: exports.SITE_NAVIGATION.mypage.partner.projects },
    { name: '관심 프로젝트', path: exports.SITE_NAVIGATION.mypage.partner.scraps },
    { name: '계정 설정', path: exports.SITE_NAVIGATION.certification },
];
exports.CLIENT_MYPROJECT_NAVIGATION = [
    { name: '전체', path: `${exports.SITE_NAVIGATION.mypage.client.projects}?step=전체`, idx: 0, state: '전체' },
    { name: '모집', path: `${exports.SITE_NAVIGATION.mypage.client.projects}?step=모집`, idx: 1, state: '모집중' },
    { name: '미팅', path: `${exports.SITE_NAVIGATION.mypage.client.projects}?step=미팅`, idx: 2, state: '미팅 진행' },
    { name: '견적', path: `${exports.SITE_NAVIGATION.mypage.client.projects}?step=견적`, idx: 3, state: '견적 진행' },
    { name: '계약', path: `${exports.SITE_NAVIGATION.mypage.client.projects}?step=계약`, idx: 4, state: '계약 진행' },
    { name: '진행', path: `${exports.SITE_NAVIGATION.mypage.client.projects}?step=진행`, idx: 5, state: '진행중' },
    { name: '완료', path: `${exports.SITE_NAVIGATION.mypage.client.projects}?step=완료`, idx: 6, state: '완료' },
];
exports.PARTNER_MYPROJECT_NAVIGATION = [
    { name: '전체', path: `${exports.SITE_NAVIGATION.mypage.partner.projects}?step=전체`, idx: 0, state: '전체' },
    { name: '지원', path: `${exports.SITE_NAVIGATION.mypage.partner.projects}?step=지원`, idx: 0, state: '지원중' },
    { name: '제안', path: `${exports.SITE_NAVIGATION.mypage.partner.projects}?step=제안`, idx: 1, state: '제안중' },
    { name: '미팅', path: `${exports.SITE_NAVIGATION.mypage.partner.projects}?step=미팅`, idx: 2, state: '미팅 진행' },
    { name: '견적', path: `${exports.SITE_NAVIGATION.mypage.partner.projects}?step=견적`, idx: 3, state: '견적 진행' },
    { name: '계약', path: `${exports.SITE_NAVIGATION.mypage.partner.projects}?step=계약`, idx: 4, state: '계약 진행' },
    { name: '진행', path: `${exports.SITE_NAVIGATION.mypage.partner.projects}?step=진행`, idx: 5, state: '진행중' },
    { name: '완료', path: `${exports.SITE_NAVIGATION.mypage.partner.projects}?step=완료`, idx: 6, state: '완료' },
];
const MYPROJECT_NAVIGATION = (userType, count) => {
    const navigation = userType === 'client' ? exports.CLIENT_MYPROJECT_NAVIGATION : exports.PARTNER_MYPROJECT_NAVIGATION;
    const navigationWithCount = navigation.reduce((acc, item) => {
        const itemWithCount = {
            ...item,
            name: `${item.name}\n${count[item.name] === null || count[item.name] === undefined ? '-' : count[item.name]} 건`,
        };
        acc.push(itemWithCount);
        return acc;
    }, []);
    return navigationWithCount;
};
exports.MYPROJECT_NAVIGATION = MYPROJECT_NAVIGATION;
exports.NEWPROJECT_NAVIGATION = [
    { name: '1. 계약 방식 선택', path: `${exports.SITE_NAVIGATION.newProjet}/1` },
    { name: '2. 진행 방식 선택', path: `${exports.SITE_NAVIGATION.newProjet}/2` },
    { name: '3. 프로젝트 분류/준비', path: `${exports.SITE_NAVIGATION.newProjet}/3` },
    { name: '4. 업체 정보 선택', path: `${exports.SITE_NAVIGATION.newProjet}/4` },
    { name: '5. 기술 분야 선택', path: `${exports.SITE_NAVIGATION.newProjet}/5` },
    { name: '6. 상세 항목', path: `${exports.SITE_NAVIGATION.newProjet}/6` },
];
/* ==============================
    프로젝트 진행상황 네비게이션
============================== */
exports.CLIENT_SESSION_NAVIGATIONS = ['등록 검수', '모집중', '미팅', '견적', '계약', '진행중', '완료'];
exports.PARTNER_SESSION_NAVIGATIONS = ['지원', '제안받음', '미팅', '견젹', '계약', '진행중', '완료'];
/* ==============================
    프로젝트 등록 - 테스트
============================== */
exports.NEWPROJECT_UNDERTAKE_NAVIGATION = [
    { name: '1. 계약 방식 선택', path: '/project/testnew?step=0&type=1' },
    { name: '2. 진행 방식 선택', path: '/project/testnew?step=0&type=1' },
    { name: '3. 프로젝트 분류/준비', path: '/project/testnew?step=2&type=1' },
    { name: '4. 업체 정보 선택', path: '/project/testnew?step=3&type=1' },
    { name: '5. 기술 분야 선택', path: '/project/testnew?step=4&type=1' },
    { name: '6. 기간 설정', path: '/project/testnew?step=5&type=1' },
    { name: '7. 상세 항목', path: '/project/testnew?step=6&type=1' },
];
exports.NEWPROJECT_RESIDENT_NAVIGATION = [
    { name: '1. 계약 방식 선택', path: '/project/testnew?step=0&type=2' },
    { name: '2. 진행 방식 선택', path: '/project/testnew?step=0&type=2' },
    { name: '3. 프로젝트 분류/준비', path: '/project/testnew?step=2&type=2' },
    { name: '4. 투입 정보', path: '/project/testnew?step=3&type=2' },
    { name: '5. 지원 조건', path: '/project/testnew?step=4&type=2' },
    { name: '6. 상세 항목', path: '/project/testnew?step=5&type=2' },
];
