"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomTable = exports.InfoTable = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const style_1 = require("./style");
function InfoTable({ contents, thWidth, tdAlign, _size }) {
    return ((0, jsx_runtime_1.jsx)(style_1.InfoTable, { thWidth: thWidth, tdAlign: tdAlign, _size: _size, children: (0, jsx_runtime_1.jsx)("tbody", { children: contents.map((item, idx) => ((0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { children: item.title }), (0, jsx_runtime_1.jsx)("td", { children: item.contents })] }, idx))) }) }));
}
exports.InfoTable = InfoTable;
function CustomTable({ category, contents }) {
    return ((0, jsx_runtime_1.jsxs)(style_1.CustomTable, { children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsx)("tr", { children: category.map((item, idx) => ((0, jsx_runtime_1.jsx)("th", { children: item }, idx))) }) }), (0, jsx_runtime_1.jsx)("tbody", { children: contents.map((content, idx) => ((0, jsx_runtime_1.jsx)("tr", { children: content.map((item, iIdx) => ((0, jsx_runtime_1.jsx)("td", { children: item }, iIdx))) }, idx))) })] }));
}
exports.CustomTable = CustomTable;
