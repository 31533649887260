"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Checkbox = exports.CheckBoxWrap = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const utils_1 = require("../../../themes/utils");
exports.CheckBoxWrap = styled_components_1.default.div `
  display: flex;
  flex-wrap: wrap;
  gap: 8px 8px;
  width: 100%;

  ${(props) => props.customStyle && (0, utils_1.customCss)(props.customStyle)}
  ${(props) => props.styleTheme === 'blockL' &&
    (0, styled_components_1.css) `
      gap: 16px;

      @media (${(props) => props.theme.media.tablet}) {
        gap: 8px;
      }
    `}

    input:disabled + label {
    opacity: 0.5;
  }
`;
const blockCss = (0, styled_components_1.css) `
  width: 100%;
  height: auto;
  text-align: center;
  border-radius: 2px;

  label {
    padding: 16px 0;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid ${(props) => props.theme.colors.gray3};
    color: ${(props) => props.theme.colors.gray8};
    font-size: ${(props) => props.theme.fontSizes.s};
  }

  input:checked + label {
    border-color: ${(props) => props.theme.colors.primaryMain};
    color: ${(props) => props.theme.colors.primaryMain};
  }

  @media (${(props) => props.theme.media.mobile}) {
    min-height: 48px;
    border-radius: 4px;

    label {
      border-radius: 4px;
    }
  }
`;
const blockLCss = (0, styled_components_1.css) `
  ${blockCss}

  width: calc(25% - 12px);
  height: 100px;
  border-radius: 4px;

  label {
    border-radius: 4px;
    border-color: ${(props) => props.theme.colors.gray2};
    font-weight: ${(props) => props.theme.fontWeights.medium};
  }

  @media (${(props) => props.theme.media.tablet}) {
    width: calc(25% - 6px);
    height: 80px;
  }
`;
const blockRCss = (0, styled_components_1.css) `
  ${blockCss}

  label {
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.gray2};
  }

  @media (${(props) => props.theme.media.tablet}) {
    label {
      border-radius: 4px;
    }
  }
`;
const blockRsCss = (0, styled_components_1.css) `
  ${blockRCss}
  height: auto;

  label {
    padding: 16px 0;
    font-size: ${(props) => props.theme.fontSizes.s};
  }

  @media (${(props) => props.theme.media.tablet}) {
    label {
      padding: 12px 0;
      font-size: ${(props) => props.theme.fontSizes.xs};
    }
  }
`;
const roundCss = (0, styled_components_1.css) `
  border-radius: 20px;
  overflow: hidden;
  label {
    padding: 8px 16px;
    border-radius: 20px;
    border: 1px solid ${(props) => props.theme.colors.gray1};
    background-color: ${(props) => props.theme.colors.gray1};
    color: ${(props) => props.theme.colors.gray8};
    font-size: ${(props) => props.theme.fontSizes.xs};
  }

  input:checked + label {
    border-color: ${(props) => props.theme.colors.primaryMain};
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primaryMain};
  }

  @media (${(props) => props.theme.media.tablet}) {
    label {
      font-size: ${(props) => props.theme.fontSizes.xxs};
    }
  }
`;
const roundBdCss = (0, styled_components_1.css) `
  ${roundCss}

  label {
    border: 1px solid ${(props) => props.theme.colors.gray2};
    background-color: ${(props) => props.theme.colors.white};
  }

  input:disabled + label {
    opacity: 1;
    background-color: ${(props) => props.theme.colors.gray2};
    color: ${(props) => props.theme.colors.gray4};
  }
`;
exports.Checkbox = styled_components_1.default.div `
  z-index: 21;
  position: relative;
  overflow: hidden;

  input {
    z-index: 22;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }

  label {
    z-index: 23;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 1.5;
    cursor: pointer;
  }

  // ${(props) => props.styleTheme === 'block' && blockCss} // no: (old new portfolio)
  ${(props) => props.styleTheme === 'blockL' && blockLCss} // old new porject
    ${(props) => props.styleTheme === 'blockR' && blockRCss} // old new project
    ${(props) => props.styleTheme === 'blockRs' && blockRsCss} // old new project
    ${(props) => props.styleTheme === 'roundBd' && roundBdCss} // old new proejct
`;
