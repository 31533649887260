"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const S = __importStar(require("./style"));
const App_1 = require("../../../App");
const ui_1 = require("../../../hooks/ui");
const style_1 = require("../../ui/Text/style");
function SortingBar({ loading = true, sortings = [{ name: '', value: '' }], defaultValue = '', total = 0, handleChange, }) {
    const isTablet = (0, react_1.useContext)(App_1.ScreenContext).tablet;
    const [sortingVisible, onSortBtnClick] = (0, ui_1.useDropDown)('sortingDp');
    function getSorgingName(value) {
        let result = '정렬';
        for (const sort of sortings) {
            if (sort.value === value)
                result = sort.name;
        }
        return result;
    }
    function onSortClick(event, value) {
        handleChange(value);
        if (isTablet && sortingVisible)
            onSortBtnClick(event);
    }
    return ((0, jsx_runtime_1.jsxs)(S.Bar, { children: [(0, jsx_runtime_1.jsx)(style_1.Text, { as: "strong", _weight: "medium", children: loading ? '' : `전체 ${total}건` }), (0, jsx_runtime_1.jsxs)("div", { className: "sorting sortingDp", children: [isTablet && ((0, jsx_runtime_1.jsx)(S.SotringBtn, { active: isTablet && sortingVisible, className: "sortingBtn", onClick: onSortBtnClick, children: getSorgingName(defaultValue) })), (0, jsx_runtime_1.jsx)(S.Sorts, { active: isTablet && sortingVisible, children: sortings.map((sorting, idx) => ((0, jsx_runtime_1.jsx)(S.Sort, { active: sorting.value === defaultValue, children: (0, jsx_runtime_1.jsx)(S.SortBtn, { active: sorting.value === defaultValue, mobile: isTablet, onClick: (event) => {
                                    onSortClick(event, sorting.value);
                                }, children: sorting.name }) }, idx))) })] })] }));
}
exports.default = SortingBar;
