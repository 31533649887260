"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPortfolioData = exports.getPortfolioModifyForm = exports.getPortfolioRegisterForm = exports.portfolioDataset = void 0;
const projectCategory_1 = require("../schemes/projectCategory");
const date_1 = require("../utils/date");
const portfolioDescription = `포트폴리오 요약\n(1000자 이하로 작성해 주세요.)\n\n포트폴리오 소개 :\n예시 ) 클라이언트 업체의 요구사항에 맞게 제작된 고객관리 시스템입니다.\n\n-   포트폴리오 장점\n예시 ) 자사에서 자체적으로 제작한 솔루션을 사용하여 제작 기간을 단축하였습니다.\n\n-   기능 리스트\n예시 ) 관리자 권한 세부 설정, 고객관리, 재고관리, 세금계산서 발행 등`;
const portfolioDataset = () => ({
    title: '',
    techCtg: [],
    techManage: [],
    techDev: [],
    techDesign: [],
    service: [],
    devLanguage: [],
    selfDevLanguage: [],
    devFramework: [],
    selfDevFramework: [],
    joinRate: '100%',
    startDate: null,
    endDate: null,
    project: '',
    images: [],
    text: portfolioDescription,
    tags: '',
});
exports.portfolioDataset = portfolioDataset;
function getPortfolioCommonForm(dataset) {
    return {
        portfolioTitle: dataset.title,
        wantTechnologyCtg: dataset.techCtg[0] === '전체' ? '기획,디자인,개발' : dataset.techCtg.toString(),
        wantTechnologyPlan: dataset.techManage.toString(),
        wantTechnologyDesign: dataset.techDesign.toString(),
        wantTechnologyDev: dataset.techDev.toString(),
        serviceSector: dataset.service.toString(),
        participation: Number(dataset.joinRate.replace('%', '')),
        startDate: dataset.startDate ? (0, date_1.stringDateFormDate)(dataset.startDate) : null,
        endDate: dataset.endDate ? (0, date_1.stringDateFormDate)(dataset.endDate) : null,
        projectClass: dataset.project,
        portfolioContent: dataset.text,
        portfolioTag: dataset.tags,
        wantDevelop: [...dataset.devLanguage, ...dataset.selfDevLanguage].toString(),
        wantFramework: [...dataset.devFramework, ...dataset.selfDevFramework].toString(),
        ...(dataset.delPortfolioFiles && { delPortfolioFiles: dataset.delPortfolioFiles }),
    };
}
function getPortfolioRegisterForm(userNo, dataset) {
    const formData = new FormData();
    const fileData = dataset.images;
    let stringData = {
        ...getPortfolioCommonForm(dataset),
        type: 0,
        userNo: userNo,
    };
    for (const file of fileData)
        formData.append('multipartFiles', file);
    formData.append('portfolio', new Blob([JSON.stringify(stringData)], { type: 'application/json' }));
    return formData;
}
exports.getPortfolioRegisterForm = getPortfolioRegisterForm;
function getPortfolioModifyForm(userNo, portfolioNo, dataset) {
    const formData = new FormData();
    const fileData = dataset.images;
    let stringData = {
        ...getPortfolioCommonForm(dataset),
        type: 1,
        userNo: userNo,
        portfolioNo: portfolioNo,
    };
    for (const file of fileData)
        formData.append('multipartFiles', file);
    formData.append('portfolio', new Blob([JSON.stringify(stringData)], { type: 'application/json' }));
    return formData;
}
exports.getPortfolioModifyForm = getPortfolioModifyForm;
function getPortfolioData(data) {
    const { startDate, endDate, portfolioContent, portfolioFiles, portfolioTag, portfolioTitle, projectClass, serviceSector, wantDevelop, wantFramework, wantTechnologyCtg, wantTechnologyPlan, wantTechnologyDesign, wantTechnologyDev, participation, } = data;
    const devLanguage = (0, projectCategory_1.getOriginAndSelfOptions)('devLanguage', wantDevelop);
    const devFramework = (0, projectCategory_1.getOriginAndSelfOptions)('devFramework', wantFramework);
    const portfolioData = {
        title: portfolioTitle,
        techCtg: wantTechnologyCtg.split(','),
        techManage: wantTechnologyPlan.split(','),
        techDesign: wantTechnologyDesign.split(','),
        techDev: wantTechnologyDev.split(','),
        service: serviceSector.split(','),
        devLanguage: devLanguage.origin,
        selfDevLanguage: devLanguage.self,
        devFramework: devFramework.origin,
        selfDevFramework: devFramework.self,
        joinRate: participation + '%',
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        project: projectClass,
        images: portfolioFiles?.map((item) => ({
            url: item.s3Link,
            name: item.s3FileName,
        })) || [],
        text: portfolioContent,
        tags: portfolioTag,
    };
    return portfolioData;
}
exports.getPortfolioData = getPortfolioData;
