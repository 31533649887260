"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Inner = exports.Main = exports.Wrapper = void 0;
const styled_components_1 = __importStar(require("styled-components"));
exports.Wrapper = styled_components_1.default.div `
  z-index: 1;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  overflow-x: ${(props) => (props.stickyChild ? 'inherit' : 'hidden')};

  header {
    z-index: 30;
  }

  footer {
    z-index: 10;
  }

  aside + main,
  header + main {
    margin-top: 80px;

    @media (${({ theme }) => theme.media.tablet}) {
      margin-top: 56px;
    }
  }

  ${(props) => props.pcWidth &&
    (0, styled_components_1.css) `
      @media (max-width: ${props.pcWidth}px) {
        width: ${props.pcWidth}px;
        max-width: ${props.pcWidth}px;
      }

      @media (${props.theme.media.tablet}) {
        width: 100%;
        max-width: 100%;
      }
    `}
`;
exports.Main = styled_components_1.default.main `
  z-index: 20;
  position: relative;
  width: 100%;
  max-width: 100%;
`;
exports.Inner = styled_components_1.default.div `
  position: relative;
  max-width: 1080px;
  padding: 0 15px;
  margin: 0 auto;
  min-height: 100vh;

  @media (${({ theme }) => theme.media.tablet}) {
    padding: 0 0 80px;
  }
`;
