"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TECH_KEY_ENG_KO = exports.TECH_KEY_KO_ENG = exports.TURN_KEY_OPTIONS = exports.TECH_TURN_KEY = exports.TECHAREA_CAGETORY2_OPTIONS = exports.TECHAREA_CAGETORY1_OPTIONS = exports.COWORK_TYPE = exports.BENEFITS = exports.DEGREES = exports.PAY_TYPE = exports.MEET_TYPE = exports.BUDGETS_FILTER = exports.DURATIONS = exports.BUDGETS = exports.WORK_POSITION = exports.IT_TEAM_TYPE = exports.RESIDENT_TYPE = exports.MANAGE_STAGE_RESIDENT = exports.MANAGE_STAGE = exports.PROCESS_TYPE = exports.CONTRACT_TYPE = exports.PROJECT_TYPE = exports.RESIDENTABLE_TYPE = exports.PARTNER_BIZ_TYPE = exports.CLIENT_BIZ_TYPE = exports.JOIN_RATE = exports.DEV_FRAMEWORK = exports.DEV_LANGUAGE = exports.SERVICE_AREAS = exports.getOriginAndSelfOptions = exports.getOptionList = void 0;
/* ==============================
    { text: '', value: '' } 포맷
    옵션 리스트 반환
============================== */
function getOptionList(valueList) {
    const optionList = valueList.map((item) => {
        if (item.hasOwnProperty('text'))
            return { ...item };
        else
            return { text: item, value: item };
    });
    return optionList;
}
exports.getOptionList = getOptionList;
/* ==============================
    기본 옵션 | 직접 입력 옵션
    구분객체 반환
============================== */
function getOriginAndSelfOptions(name, values) {
    const result = {
        origin: [],
        self: [],
    };
    if (values === null || values.length <= 0)
        return result;
    let originOptions = [];
    const options = values.split(',');
    switch (name) {
        case 'devLanguage':
            originOptions = exports.DEV_LANGUAGE;
            break;
        case 'devFramework':
            originOptions = exports.DEV_FRAMEWORK;
            break;
        default:
            break;
    }
    for (let option of options) {
        if (originOptions.indexOf(option) >= 0)
            result.origin.push(option);
        else
            result.self.push(option);
    }
    return result;
}
exports.getOriginAndSelfOptions = getOriginAndSelfOptions;
/* ==============================
    서비스 분야
============================== */
exports.SERVICE_AREAS = [
    '가구 / 인테리어',
    '건설 / 분양 / 부동산',
    '게임',
    '결혼',
    '공공기관 / 공기업',
    '교육 / 학원',
    '금융 / 대출',
    '디지털 / 가전',
    '반려동물',
    '병원',
    '보험',
    '뷰티 / 미용',
    '생활 / 잡화',
    '쇼핑몰',
    '스타트업',
    '스포츠 / 레저',
    '식품 / 음료',
    '어플리케이션',
    '엔터테인먼트',
    '여행',
    '유통 / 서비스',
    '음식점',
    '의류 / 패션',
    '인쇄 / 출판',
    '인터넷/서비스업',
    '자동차',
    '주식 / 증권',
    '프랜차이즈',
    '호텔 / 숙박',
    '기타',
];
/* ==============================
    개발 언어
============================== */
exports.DEV_LANGUAGE = [
    'JavaScript',
    'Java',
    'C',
    'C#',
    'C++',
    'Classic Visual Basic',
    'python',
    'Kotlin',
    'Swift',
    'Objective-C',
    'PHP',
    'GO',
    'SQL',
    'HTML/CSS',
    'VBA',
];
/* ==============================
    개발 프레임워크
============================== */
exports.DEV_FRAMEWORK = [
    'Jquery',
    'React.js',
    'Angular/Angular.js',
    'ASP.NET',
    'Express',
    'Spring',
    'Vue.js',
    'Django',
    'Laravel',
    'Node.js',
    '.net',
    'Unity',
    'React Native',
    'Cordova',
    'VBA',
];
/* ==============================
    참여율
============================== */
exports.JOIN_RATE = ['100%', '90%', '80%', '70%', '60%', '50%', '40%', '30%', '20%', '10%', '0%'];
/* ==============================
    회사 유형
============================== */
exports.CLIENT_BIZ_TYPE = ['예비사업자', '개인사업자', '법인사업자'];
exports.PARTNER_BIZ_TYPE = ['프리랜서', '개인사업자', '법인사업자'];
/* ==============================
    상주 가능 유형
============================== */
exports.RESIDENTABLE_TYPE = ['가능', '불가능', '협의 '];
/* ==============================
    프로젝트 유형
============================== */
exports.PROJECT_TYPE = [
    { text: '일반 프로젝트', value: '일반 프로젝트' },
    { text: '정부 지원 사업', value: '정부 지원 사업' },
];
/* ==============================
    계약 유형 (프로젝트 등록)
============================== */
exports.CONTRACT_TYPE = [
    { text: { main: '도급 (외주)', sub: '프로젝트 단위 계약 진행' }, value: '도급' },
    { text: { main: '상주 (지정 장소)', sub: '인력 별 시간/기간제 계약 진행' }, value: '상주' },
];
/* ==============================
    기획 준비 (프로젝트 등록)
============================== */
exports.PROCESS_TYPE = [
    { text: { main: '신규', sub: '신규 프로젝트' }, value: '신규' },
    { text: { main: '리뉴얼/고도화', sub: '운영중이며 고도화 필요' }, value: '리뉴얼/고도화' },
    { text: { main: '유지보수', sub: '운영중이며 유지보수 필요' }, value: '유지보수' },
];
exports.MANAGE_STAGE = [
    { text: '아이디어만 있어요', value: '아이디어' },
    { text: '상세문서(기획, 시안 등)가 있어요', value: '상세문서' },
];
exports.MANAGE_STAGE_RESIDENT = ['팀빌딩 중', '킥오프 대기', '진행 중'];
exports.RESIDENT_TYPE = ['상주', '반상주', '재택'];
/* ==============================
    IT 팀 유무
============================== */
exports.IT_TEAM_TYPE = ['기획자', '디자이너', '개발자', '없음'];
/* ==============================
    업무 포지션
============================== */
exports.WORK_POSITION = ['PM', '개발', '디자인', '기획'];
/* ==============================
    예산 (프로젝트 등록)
============================== */
exports.BUDGETS = [
    { text: '1 ~ 500만 원', value: '10000,5000000' },
    { text: '501 ~ 1,000만 원', value: '5010000,10000000' },
    { text: '1,001 ~ 3,000만 원', value: '10010000,30000000' },
    { text: '3,001 ~ 5,000만 원', value: '30010000,50000000' },
    { text: '5,001 ~ 1억 원', value: '50010000,100000000' },
    { text: '1억 원 초과', value: '100010000,0' },
    { text: '직접 입력', value: '직접입력' },
];
/* ==============================
    예상 기간 (프로젝트 등록)
============================== */
exports.DURATIONS = [
    { text: '7일', value: '7' },
    { text: '14일', value: '14' },
    { text: '21일', value: '21' },
    { text: '30일', value: '30' },
    { text: '60일', value: '60' },
    { text: '90일', value: '90' },
    { text: '120일', value: '120' },
    { text: '150일', value: '150' },
    { text: '180일', value: '180' },
    { text: '180일 이상', value: '181' },
    { text: '직접 입력', value: '직접입력' },
];
/* ==============================
    예산 (검색 필터)
============================== */
exports.BUDGETS_FILTER = [
    { text: '1 ~ 500만 원', value: '10000,5000000' },
    { text: '501 ~ 1,000만 원', value: '5010000,10000000' },
    { text: '1,001 ~ 3,000만 원', value: '10010000,30000000' },
    { text: '3,001 ~ 5,000만 원', value: '30010000,50000000' },
    { text: '5,001 ~ 1억 원', value: '50010000,100000000' },
    { text: '1억 원 초과', value: '100010000,0' },
];
exports.MEET_TYPE = ['대면', '비대면'];
/* ==============================
    지급 (프로젝트 등록)
============================== */
exports.PAY_TYPE = [
    { text: '에스크로 지급', value: '에스크로' },
    { text: '일반 지급', value: '일반' },
];
/* ==============================
    스펙
============================== */
exports.DEGREES = ['무관', '초대졸이상', '석사이상', '박사이상'];
/* ==============================
    복리후생
============================== */
exports.BENEFITS = ['장비 지원', '중식 제공', '야근 및 휴일 근무 수당', '체재비'];
/* ==============================
    협업 여부 (프로젝트 등록)
============================== */
exports.COWORK_TYPE = [
    { text: '1인 작업', value: '1' },
    { text: '2인 작업', value: '2' },
    { text: '4인 작업', value: '4' },
    { text: '10인 작업', value: '10' },
    { text: '10인 이상 작업', value: '11' },
];
exports.TECHAREA_CAGETORY1_OPTIONS = [
    {
        text: 'Turn Key',
        value: 'Turn Key',
        category2: ['Android 앱', 'iOS 앱', '웹사이트', '영상', '게임', '소프트웨어'],
    },
    {
        text: '기획',
        value: '기획',
        category2: ['Android 앱', 'iOS 앱', '웹사이트', '영상', '게임', '소프트웨어', '임베디드', '데이터 설계'],
    },
    { text: '디자인', value: '디자인', category2: ['Android 앱', 'iOS 앱', '웹사이트', '영상', '게임', '소프트웨어'] },
    {
        text: '개발',
        value: '개발',
        category2: ['Android 앱', 'iOS 앱', '웹사이트', '영상', '게임', '소프트웨어', '임베디드', '퍼블리싱'],
    },
];
exports.TECHAREA_CAGETORY2_OPTIONS = [
    { text: 'Android 앱', value: 'Android 앱', category1: ['기획', '디자인', '개발', 'Turn Key'] },
    { text: 'iOS 앱', value: 'iOS 앱', category1: ['기획', '디자인', '개발', 'Turn Key'] },
    { text: '웹사이트', value: '웹사이트', category1: ['기획', '디자인', '개발', 'Turn Key'] },
    { text: '영상', value: '영상', category1: ['기획', '디자인', '개발', 'Turn Key'] },
    { text: '게임', value: '게임', category1: ['기획', '디자인', '개발', 'Turn Key'] },
    { text: '소프트웨어', value: '소프트웨어', category1: ['기획', '디자인', '개발', 'Turn Key'] },
    { text: '임베디드', value: '임베디드', category1: ['기획', '개발'] },
    { text: '데이터 설계', value: '데이터 설계', category1: ['기획'] },
    { text: '퍼블리싱', value: '퍼블리싱', category1: ['개발'] },
];
exports.TECH_TURN_KEY = 'Turn Key';
exports.TURN_KEY_OPTIONS = ['Android 앱', 'iOS 앱', '웹사이트', '영상', '게임', '소프트웨어'];
exports.TECH_KEY_KO_ENG = {
    개발: 'techDev',
    기획: 'techManage',
    디자인: 'techDesign',
};
exports.TECH_KEY_ENG_KO = {
    techDev: '개발',
    techManage: '기획',
    techDesign: '디자인',
};
